import '../InputTextBo/InputTextBo.css';

import { ClearOrClose } from '../../../icons';
import { useDispatch } from 'react-redux';
import { IconSize } from '../../../Services/MediaQueryServices';

import * as loginDestination from '../../../Features/LoginData/LoginDataSlice';

const InputTextBo = ( { name, id, type, placeholder, payload, store, form, validation, title, onKeyDown }) => {

    const dispatch = useDispatch();
    const size = IconSize();
    
    const allLoginDataStores = Object.values(loginDestination);
    const allLoginDataStoresNames = Object.keys(loginDestination);
    const indexLoginStore = allLoginDataStoresNames.findIndex(loginStore => loginStore === store)

    //COMMENT - Do we need to validate input on login? 
    //const inputApproved = new RegExp(validation,'ig');
    // useEffect(() => {
    //     if(payload !== ""){ setIsApproved(true);}
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[])
    // #end region

    const handleInput = (event) => {
        const newValue = event.target.value;
        if(allLoginDataStores[indexLoginStore]) {

            dispatch(allLoginDataStores[indexLoginStore](newValue));
        }
    }
    const resetInput = () => {
        if(allLoginDataStores[indexLoginStore]) {

            dispatch(allLoginDataStores[indexLoginStore](""))
        }
    }

    return(
        <div className="login-input-container">

            <label className="login-input-label" htmlFor={ name }>{placeholder}</label>
            <input
                name={ name }
                id={ id }
                type={ type }
                className='login-input'
                title={ title }
                placeholder={ placeholder }
                value={ payload }
                onChange={ handleInput }
                onKeyDown={ onKeyDown }
                required
            />

            { payload === "" || payload === undefined ?
                null :
                <div className='login-icon-container' onClick={ resetInput }>
                    <ClearOrClose color="var(--(dark-color)" size={ size } className="clear-close" />
                </div>
            }

        </div>
    );
}

export default InputTextBo;