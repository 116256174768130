import { configureStore } from "@reduxjs/toolkit";
import municipalityReducer from "./Features/Municipality/MunicipalitySlice"
import obstacleTypeReducer from "./Features/ObstacleType/ObstacleTypeSlice";
import formsDataReducer from "./Features/FormsData/FormsDataSlice";
import loginDataReducer from "./Features/LoginData/LoginDataSlice";
import issueReducer from "./Features/Issue/IssueSlice";
import backofficeReducer from './Features/BackofficeData/BackofficeDataSlice';
import reCaptchaReducer from "./Features/ReCaptcha/ReCaptchaSlice";

export const store = configureStore({
    reducer: {
        municipality: municipalityReducer,
        obstacleType: obstacleTypeReducer,
        formsData: formsDataReducer,
        loginData: loginDataReducer,
        issue: issueReducer,
        backofficeData: backofficeReducer,
        reCaptcha: reCaptchaReducer,
    },
});