
import '../DateBo/DateBo.css';

import { storeBoDate } from '../../../Features/BackofficeData/BackofficeDataSlice';

import { useDispatch } from 'react-redux';
// import { useState } from 'react';
// import { DateTime } from 'luxon';



const DateBo = ({ name, id, form, min, max, defaultDate, title, labelText }) => {
    const dispatch = useDispatch();
    // const [dateValue, setDateValue] = useState(defaultDate);
    //COMMENT:When trying to make the element controlled a refresh messes everything up as the proper date is not created.
    //const dateInput = document.getElementById( id );

    // console.log("dateVALUE",dateValue)
    // console.log("defaultVALUE", defaultDate)
 
    const handleChange = ( event ) => {
         id === 'bo-date-from' ? 
         dispatch(storeBoDate({ min:event.target.value, max: max })) : 
         dispatch(storeBoDate({ min: min, max: event.target.value })) ;

        //  setDateValue(event.target.value);
    };

    return(
        <div className="bo-date-inner-container">
             <div className="bo-date">
             <label htmlFor={ name } className='bo-date-label'>{ labelText }</label>
                <input name={ name } id={ id } type="date" className={ form } title={ title }
                    value={ defaultDate } onChange={ handleChange } />
            </div>
        </div>
       
            
    );
}

export default DateBo;