import '../InputText/InputText.css';

import { ClearOrClose } from '../../../icons';
import * as destination from '../../../Features/FormsData/FormsDataSlice'
import { IconSize } from '../../../Services/MediaQueryServices';

import { useState } from 'react';
import { useDispatch } from 'react-redux';


const InputTextArea = ({ name, id, placeholder, payload, store, rows, validation, title, label }) => {

    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState("");
    const [isApproved, setIsApproved] = useState(false);
    const size = IconSize();
    const inputApproved = new RegExp(validation, 'ig');
    const allFormsDataStores = Object.values(destination); //Array of all store functions
    const allFormsDataStoresNames = Object.keys(destination); //Array of all store names as strings
    const index = allFormsDataStoresNames.findIndex(dataStore => dataStore === store); //index of correct function

    const handleInput = (event) => {
        if (inputApproved.test(event.target.value)) {
            if (event.target.value === "") { setIsApproved(false) }
            dispatch(allFormsDataStores[index](event.target.value));
            setInputValue(event.target.value);
        }
    }

    const resetInput = () => {
        dispatch(allFormsDataStores[index](""));
        setInputValue("");
        setIsApproved(false);
    }

    return (
        <div className="input-container">
            <label htmlFor={name}>{label}</label>
            {payload === "" ?
                <textarea name={name} id={id} className={isApproved ? 'form-input approved-input' : 'form-input'} style={{ resize: 'none' }}
                    rows={rows} title={title} placeholder={placeholder} value={inputValue} onChange={handleInput} required /> :
                <textarea name={name} id={id} className='form-input approved-input' rows={rows} title={title}
                    placeholder={placeholder} value={payload} onChange={handleInput} style={{ resize: 'none' }} />
            }

            {payload === "" || payload === undefined ?
                null :
                <span className='input-icon-container' onClick={resetInput}>
                    <ClearOrClose color="var(--(dark-color)" size={size} className="clear-close" />
                </span>
            }

        </div>
    );
}

export default InputTextArea;