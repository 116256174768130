import '../Error/Error.css';

import DHRLogo from '../../Components/Btns, icons & logos/DHRLogos/DHRLogo';
import * as Constants from '../../constants';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const Error = () => {

    const municipality = useSelector((state) => state.municipality);
    const obstacle = useSelector((state) => state.obstacleType);
    const formsData = useSelector((state) => state.formsData);
    const [municipalityError, setMunicipalityError] = useState(false);
    const [obstacleError, setObstacleError] = useState(false);
    const [formsDataError, setFormsDataError] = useState(false);

    useEffect(() => {
        if( municipality.errorMessage !== "" ) { setMunicipalityError(true); }
        if( obstacle.errorMessage !== "" ) { setObstacleError(true); }
        if( formsData.errorMessage !== "" ) { setFormsDataError(true); }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    
    return(
        <div className='error-main-container'>
            <div className='error-logo-container'>
                <DHRLogo />
            </div>
            <div className="error-text-container">
                <h1 className='error-main-header'>{Constants.ErrorMessages.mainHeader}</h1>
                <p className='error-p'>{ Constants.ErrorMessages.information }</p>
                <p className='error-p'>{ Constants.ErrorMessages.instruction }</p>
                <a href='mailto:jonas.andersson@toxic.se'>{Constants.Misc.appContactMail}</a> 
                <h1 className='error-sub-header'>{ Constants.ErrorMessages.errorInformationHeader }</h1>

                { municipalityError ?
                <div className="error-code-container">
                    <label className='error-code-label'>{ Constants.ErrorMessages.errorMessage }</label>
                    <code className='error-code'>{ municipality.errorMessage }</code> 
                    <label className='error-code-label'>{ Constants.ErrorMessages.errorType }</label>
                    <code className='error-code'>{ municipality.errorType }</code>
                </div> :
                null
                }
                <br />
                { obstacleError ?
                <div className="error-code-container">
                    <label className='error-code-label'>{ Constants.ErrorMessages.errorMessage }</label>
                    <code className='error-code'>{ obstacle.errorMessage }</code> 
                    <label className='error-code-label'>{ Constants.ErrorMessages.errorType }</label>
                    <code className='error-code'>{ obstacle.errorType }</code>
                </div> :
                null
                }
                { formsDataError ?
                <div className="error-code-container">
                    <label className='error-code-label'>{ Constants.ErrorMessages.errorMessage }</label>
                    <code className='error-code'>{ formsData.errorMessage }</code> 
                    <label className='error-code-label'>{ Constants.ErrorMessages.errorType }</label>
                    <code className='error-code'>{ formsData.errorType }</code>
                </div> :
                null
                }

            </div>
            {/* <Link to={ '/contact' }>

                <div className="error-btn-container">
                    <div className="error-btn-icon-container">{ <BackStep size={ size }/>}</div>
                    <div className="error-btn-text">{ Constants.GDPR.button}</div>
                </div>
            </Link> */}
        </div>
    );
}

export default Error;