import '../InputText/InputText.css';
import { ClearOrClose } from '../../../icons';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as destination from '../../../Features/FormsData/FormsDataSlice';
import * as loginDestination from '../../../Features/LoginData/LoginDataSlice';
import { IconSize } from '../../../Services/MediaQueryServices';

const InputText = ({ name, id, type, placeholder, payload, store, form, validation, title, label }) => {

    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState("");
    const [isApproved, setIsApproved] = useState(false);
    const [isError, setIsError] = useState(false);
    const inputApproved = new RegExp(validation, 'ig');
    const size = IconSize();

    useEffect(() => {
        if (payload !== "") { setIsApproved(true); }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (form === "issue-form") {
        var allFormsDataStores = Object.values(destination); //Array of all store functions
        var allFormsDataStoresNames = Object.keys(destination); //Array of all store names as strings
        var indexFormStore = allFormsDataStoresNames.findIndex(dataStore => dataStore === store);
    }
    else if (form === "login-form") {
        var allLoginDataStores = Object.values(loginDestination);
        var allLoginDataStoresNames = Object.keys(loginDestination);
        var indexLoginStore = allLoginDataStoresNames.findIndex(loginStore => loginStore === store)
    }

    const handleInput = (event) => {
        if (form === "issue-form") {
            if (inputApproved.test(event.target.value)) {
                setIsApproved(true);
                setIsError(false);
                dispatch(allFormsDataStores[indexFormStore](event.target.value));
                setInputValue(payload);
            }
            else if (!inputApproved.test(event.target.value)) {
                setIsApproved(false);
                setIsError(true);
                dispatch(allFormsDataStores[indexFormStore](event.target.value));
                //if(event.target.value === ""){ setIsApproved(false)}
                setInputValue(event.target.value);
            }
        }
        else if (form === "login-form") {
            setIsApproved(false);
            dispatch(allLoginDataStores[indexLoginStore](event.target.value));
            setInputValue(payload);
        }
    }

    const resetInput = () => {
        form === "issue-form" ? dispatch(allFormsDataStores[indexFormStore]("")) :
            dispatch(allLoginDataStores[indexLoginStore](""))
        setInputValue("");
        setIsApproved(false);
    }

    return (
        <div className="input-container">
            <label htmlFor={name}>{label}</label>
            <div className="input-icon">
                {payload === "" || payload === undefined ?
                    <input name={name} id={id} type={type} className='form-input'
                        title={title} placeholder={placeholder} value={inputValue} onChange={handleInput} required /> :
                    <input name={name} id={id} type={type} className={isApproved ? 'form-input approved-input' : (isError ? 'form-input error-input' : 'form-input')} title={title}
                        placeholder={placeholder} value={payload} onChange={handleInput} required />
                }

                {payload === "" || payload === undefined ?
                    null :
                    <span className='input-icon-container' onClick={resetInput}>
                        <ClearOrClose color="var(--(light-color)" size={size} className="clear-close" />
                    </span>
                }
            </div>

        </div>
    );
}

export default InputText;