import '../ObstacleForm/ObstacleForm.css';

import Select from '../Select/Select';
import InputText from '../InputText/InputText';
import SearchBar from '../../Forms & Inputs/Searchbar/SearchBar';
import TextArea from '../InputTextArea/InputTextArea';
import Photo from '../Photo/Photo';
import { IconSize } from '../../../Services/MediaQueryServices';
import * as Constants from '../../../constants';

import { useSelector } from 'react-redux';


const ObstacleForm = () => {

    const { municipalities } = useSelector((state) => state.municipality);
    const { obstacleTypes } = useSelector((state) => state.obstacleType);
    const { place, description, obstacleName, photo, municipalityShortName } = useSelector((state) => state.formsData);
    const size = IconSize();
    let rows = 3;


    switch (size) {
        case '1rem':
            rows = 2;
            break;
        case '1.2rem':
            rows = 3;
            break;
        case '1.5rem':
            rows = 4;
            break;
        default:
            break;
    }
    //Tips on RegEx-validation taken from: https://blog.openreplay.com/regular-expressions-and-input-validations/
    return (
        <form id="form">
            <Select name="select-type"
                id="select-type"
                placeholder={"Välj här..."}
                obstacleName={obstacleName}
                data={obstacleTypes}
                label={"* Välj typ av hinder"}
                title={Constants.Obstacle.toolTipType} />


            <InputText name="obstacle-place"
                id="obstacle-place"
                type="text"
                label={Constants.Obstacle.place}
                payload={place}
                store={"storeObstaclePlace"}
                form="issue-form"
                validation="^[A-ZÅÄÖ0-9_\- ]{0,25}$"
                title={Constants.Obstacle.toolTipPlace}
                placeholder={"Skriv här..."} />

            <SearchBar name="search-municipality"
                id="search-municipality"
                type="text"
                placeholder={"Sök här..."}
                payload={municipalityShortName}
                data={municipalities}
                title={Constants.Obstacle.toolTipSearch}
                label={Constants.Obstacle.search} />

            <TextArea name="obstacle-desc"
                id="obstacle-desc"
                rows={rows}
                placeholder={"Skriv här..."}
                payload={description}
                store={"storeObstacleDescription"}
                validation="^[A-ZÅÄÖ0-9_\-., ]{0,200}$"
                title={Constants.Obstacle.toolTipDesc}
                label={Constants.Obstacle.desc} />

            <Photo payload={photo}
                title={Constants.Obstacle.toolTipPhoto} />
        </form>
    );
}

export default ObstacleForm;