import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userName: "",
    password: "",
    token: "",
};

const loginDataSlice = createSlice({
    name: 'loginData',
    initialState,
    reducers: {
        storeUsername: ( state, userName ) => {
            state.userName = userName.payload;
        },
        storePassword: ( state, password ) => {
            state.password = password.payload;
        },
        storeToken: ( state, token ) => {
            state.token = token.payload;
        }
    }
});

export const {
                storeUsername,
                storePassword,
                storeToken
            } = loginDataSlice.actions;
            
export default loginDataSlice.reducer;