import '../BtnLgInv/BtnLgInv.css';

const BtnLgInv = ( {  text, icon, whenClicked } ) => {
    return(
        <div className="inv-btn-lg-container" onClick={ whenClicked }>
            <div className="inv-btn-lg-content">
                <div className="inv-btn-lg-text">{ text }</div>
                <div className="inv-btn-lg-icon-container">{ icon }</div>
            </div>
            <div className="inv-btn-lg"></div>
        </div>
    );
}

export default BtnLgInv;