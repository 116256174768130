import * as Constants from '../../constants';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    obstacleId: 0,
    obstacleName: "",
    place: "",
    municipalityId: 0,
    municipalityShortName: "",
    municipalityLongName: "",
    description: "",
    photo: "",
    photoName: "",
    
    name: "",
    email: "",
    phone:"",
    wantFeedback: false,
    approveGDPR: false,

    errorMessage: "",
    errorType: ""
};

export const SendData = createAsyncThunk('formsdata/sendData', async ( data ) => {
  
     const devUrl = Constants.URLs.postIssue;
     const prodUrl = Constants.URLs.base + devUrl;
     let url = "";
     process.env.NODE_ENV === 'development' ? url = devUrl : url = prodUrl;

    //console.log("PHOTODATA", data.photo, data.photoName);

    let photo = "";
    let photoName ="";

     if (data.photo === "" || data.photoName === "") {
            photo = "nan";
            photoName = "nan";
     } else { 
        photo = data.photo;
        photoName = data.photoName;
    }

     const formData = new FormData();

     formData.append('MunicipalityId', data.municipalityId);
     formData.append('Location', data.place);
     formData.append('Description', data.description);
     formData.append('Image64Base', photo);
     formData.append('ImageName', photoName);
     formData.append('Name', data.name);
     formData.append('Email', data.email);
     formData.append('PhoneNumber', data.phone);
     formData.append('ObstacleId', data.obstacleId);
     formData.append('WantsFeedback', data.wantFeedback);
     formData.append('Processed', false);

     const config = { headers: { 'Content-Type': 'multipart/formdata' } };  

     return await axios.post(url, formData, config)
          .then(response =>{
            console.log("Svar från Backend", response)
            return response.data
        })
          .catch((error) => {
            console.log("Error in SendData, not action",error);
            throw error 
        })
         
  })

const formsDataSlice = createSlice({
    name: "formsData",
    initialState,
    reducers: {
        storeObstaclePlace: ( state, place ) => {
            state.place = place.payload;
        },
        storeObstacleDescription: ( state, description ) => {
            state.description = description.payload;
        },
        storeObstacleMunicipalityId: ( state, municipalityId ) => {
            state.municipalityId = municipalityId.payload;
        },
        storeObstacleMunicipalityShortName: ( state, municipalityShortName ) => {
            state.municipalityShortName = municipalityShortName.payload;
        },
        storeObstacleMunicipalityLongName: ( state, municipalityLongName ) => {
            state.municipalityLongName = municipalityLongName.payload;
        },
        resetObstacleMunicipality: ( state ) => {
            state.municipalityId = initialState.municipalityId;
            state.municipalityShortName = initialState.municipalityShortName;
            state.municipalityLongName = initialState.municipalityLongName;
        },
        storeObstacleId: ( state, id ) => {
            console.log("Setting obstacleId to", id)
            state.obstacleId = id.payload;
        },
        storeObstacleName: ( state, name ) => {
            state.obstacleName = name.payload;
        },
        storePhoto: ( state, photo ) => {
            state.photo = photo.payload;
        },
        storePhotoName: ( state, photoName ) => {
            state.photoName = photoName.payload;
        },
        storeName: ( state, name ) => {
            state.name = name.payload;
        },
        storeEmail: ( state, email ) => {
            state.email = email.payload;
        },
        storePhone: ( state, number ) => {
            state.phone = number.payload;
        },
        storeConfirmation: ( state, answer ) => {
            state.wantFeedback = answer.payload;
        },
        storeGDPR: ( state, answer ) => {
            state.approveGDPR = answer.payload;
        },
        resetForms: ( state ) => {
            state.municipalityId = initialState.municipalityId;
            state.municipalityShortName = initialState.municipalityShortName;
            state.municipalityLongName = initialState.municipalityLongName;
            state.obstacleId = initialState.obstacleId;
            state.obstacleName = initialState.obstacleName;
            state.place = initialState.place;
            state.municipalityId = initialState.municipalityId;
            state.municipalityShortName = initialState.municipalityShortName;
            state.municipalityLongName = initialState.municipalityLongName;
            state.description = initialState.description;
            state.photo = initialState.photo;
            state.photoName = initialState.photoName;
            state.name = initialState.name;
            state.email = initialState.email;
            state.phone = initialState.phone;
            state.wantFeedback = initialState.wantFeedback;
            state.approveGDPR = initialState.approveGDPR;
        },
    },
    extraReducers: {
        [SendData.pending]: ( state ) => {
            state.isLoading = true;
        },
        [SendData.fulfilled]: ( state, action ) => {
            console.log(action);
            state.isLoading = false;
            // state.municipalities = action.payload;
        },
        [SendData.rejected]: ( state, action ) => {
            state.isLoading = false;
            console.log("ERROR ACTION SEND", action);
            //alert(`Ett fel inträffade när datan skickades: ${action.error.message}`)
            state.errorMessage = action.error.message;
            state.errorType = action.type;
        }
    }
});

export const { 
               storeObstaclePlace, 
               storeObstacleDescription,
               storeObstacleMunicipalityId,
               storeObstacleMunicipalityShortName,
               storeObstacleMunicipalityLongName,
               resetObstacleMunicipality,
               storeObstacleId,
               storeObstacleName,
               storePhoto,
               storePhotoName,
               storeName,
               storeEmail,
               storeGDPR,
               storePhone,
               storeConfirmation,
               resetForms,
             } 
               = formsDataSlice.actions;

export default formsDataSlice.reducer;