import '../ContactForm/ContactForm.css';

import InputText from '../InputText/InputText';
import Checkbox from '../Checkbox/Checkbox';
import * as Constants from '../../../constants';

import { useDispatch, useSelector } from 'react-redux';
import { storeConfirmation, storeGDPR } from '../../../Features/FormsData/FormsDataSlice';

const ContactForm = () => {

        const { name, email, phone, wantFeedback, approveGDPR } = useSelector((state) => state.formsData);

        const dispatch = useDispatch();

        //Tips on RegEx-validation taken from: https://blog.openreplay.com/regular-expressions-and-input-validations/
        return (
                <form id="form">
                        <InputText name="name"
                                id="name"
                                type="text"
                                placeholder={"Skriv för- och efternamn här..."}
                                payload={name}
                                store={"storeName"}
                                form="issue-form"
                                title={Constants.Contact.toolTipName}
                                label={Constants.Contact.name} />

                        <InputText name="email"
                                id="email"
                                type="email"
                                placeholder={"Skriv din epostadress här..."}
                                payload={email}
                                store={"storeEmail"}
                                form="issue-form"
                                validation={"^([a-z0-9.-]{1,25})@([a-z0-9-]{2,25})\\.([a-z0-9-]{2,5})$"}
                                title={Constants.Contact.toolTipEmail}
                                label={Constants.Contact.email}
                        />

                        <InputText name="phone"
                                id="phone"
                                type="tel"
                                placeholder={"Skriv dit telefonnummer här..."}
                                payload={phone}
                                store={"storePhone"}
                                form="issue-form"
                                validation="^([0-9-]{8,12})$"
                                title={Constants.Contact.toolTipPhone}
                                label={Constants.Contact.phone}
                        />

                        <Checkbox name="confirmation"
                                label={Constants.Contact.followUp}
                                checked={wantFeedback}
                                whenChanged={() => dispatch(storeConfirmation(!wantFeedback))} />

                        <Checkbox name="gdpr"
                                label={Constants.Contact.approve}
                                checked={approveGDPR}
                                whenChanged={() => dispatch(storeGDPR(!approveGDPR))} />
                </form>
        );
}

export default ContactForm;