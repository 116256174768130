
export const GetNextPage = () => {
    switch ( window.location.pathname ) {
      case '/':
        return '/obstacle';
      case '/obstacle':
        return '/contact';
      case '/contact':
        return '/summary';
      case '/summary':
        return '/thankyou';
      case '/login':
        return '/';
      case '/gdpr':
        return '/contact';
      default:
        break;
    }
  }

  export const GetPreviousPage = () => {
    switch ( window.location.pathname ) {
      case '/thankyou':
        return '/summary';
      case '/summary':
        return '/contact';
      case '/contact':
        return '/obstacle';
      case '/obstacle':
        return '/';
      default:
        break;
    }
  }