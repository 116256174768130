import '../SelectBo/SelectBo.css';
import { ArrowDown, ArrowUp, ClearOrClose } from '../../../icons';
import { storeBoMunicipalityId, storeBoIssuesPerPage, storeBoObstacleId } from '../../../Features/BackofficeData/BackofficeDataSlice';
import * as Constants from '../../../constants';

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const SelectBo = ( { name, id, placeholder, data, label } ) => {

    const dispatch = useDispatch();
    const [ isListActive, setIsListActive ] = useState(false);
    const [ value, setValue ] = useState("");
    const selectElement = document.getElementById( id );
    const municipalityArr = [];
    

    data.forEach( (municipality) => municipalityArr.push( <option className='selected' key={ municipality.value } value={ municipality.value }>{ municipality.label }</option>));

    useEffect( () => {
        setValue( placeholder );
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 
    
    const handleSelect = ( event ) => {
        if(id === "municipality-select") {
            dispatch(storeBoMunicipalityId(parseInt(event.target.value)))
        } else if(id === "obstacle-select") {
            dispatch(storeBoObstacleId(parseInt(event.target.value)));
        }
        else {
            dispatch(storeBoIssuesPerPage(parseInt(event.target.value)));
        }
        setValue("");
    };
    const ClearSelect = () => {
        if(id === "municipality-select") {
            dispatch(storeBoMunicipalityId(0))
        } else if(id === "obstacle-select") {
            dispatch(storeBoObstacleId(0));
        } 
        else {
            dispatch(storeBoIssuesPerPage(0));
        }
        setValue( placeholder ); 
        selectElement.value = Constants.Obstacle.default;
    };

    return(
        <div className="select-container">
            <label htmlFor={ name } defaultValue={ Constants.Obstacle.default}>{ label }</label>
            <select name={ name } id={ id }  className="bo-select"
                    onChange={ handleSelect }
                    onClick={() => isListActive ? setIsListActive(false) : setIsListActive(true)} onBlur={() => setIsListActive(false)} required>
                    <option value={ placeholder }>{ placeholder }</option>
                    { municipalityArr }
            </select> 
            <span className="bo-input-icon-container" onClick={ ClearSelect }>
                {   isListActive ?
                    <ArrowUp color="var(--(dark-color)" size="0.9rem" /> :
                    value === placeholder ?
                    <ArrowDown color="var(--(dark-color)" size="0.9rem" /> :
                    <ClearOrClose color="var(--(dark-color)" size="0.9rem" />
                }
            </span>
        </div>
    );
}

export default SelectBo;