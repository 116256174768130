import '../3_ContactInformation/ContactInformation.css';

import * as Constants from '../../constants';
import ContactForm from '../../Components/Forms & Inputs/ContactForm/ContactForm';
import { ContactFormApproved } from '../../Services/FormsServices';

import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { ObstacleFormApproved } from '../../Services/FormsServices';

const ContactInformation = () => {

    const navigate = useNavigate();
    const formsData = useSelector((state) => state.formsData);

    useEffect(() => {
    if(!ObstacleFormApproved( formsData )){ navigate('/obstacle');  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <div className='contact-main-container'>
            <div className='contact-form-container'>
                <ContactForm/>
            </div>
            <p className={ ContactFormApproved(formsData) ? 'form-message display-none' : 'form-message' }>{ Constants.Obstacle.required }</p>
            <div className="fineprint">
                <Link to={'/gdpr'}>{ Constants.Contact.GDPR } </Link>
            </div>
        </div>
    );
}

export default ContactInformation;