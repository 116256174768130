import * as Constants from '../../constants';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    municipalities: [],
    isLoading: true,

    errorMessage: "",
    errorType:"",
    errorMetaRequestId: "",
};

export const GetMunicipalities = createAsyncThunk('municipality/getMunicipalities',  async () => {
    const devUrl = Constants.URLs.getMunicipalities;
    const prodUrl = Constants.URLs.base + devUrl;
    let url = "";
    process.env.NODE_ENV === 'development' ? url = devUrl : url = prodUrl;
    return await axios.get(url).then(response =>{
        console.log(response)
        return response.data;
    }).catch((error) => {
        console.log("kommunFEL",error)
        throw error;
    })
})

const municipalitySlice = createSlice({
    name: 'municipality',
    initialState,
    extraReducers: {
        [GetMunicipalities.pending]: ( state ) => {
            state.isLoading = true;
        },
        [GetMunicipalities.fulfilled]: ( state, action ) => {
            // console.log(action);
            state.isLoading = false;
            state.municipalities = action.payload;
        },
        [GetMunicipalities.rejected]: ( state, action ) => {
            state.isLoading = false;
            console.log("ERROR", action);
            state.errorMessage = action.error.message;
            state.errorType = action.type;
            state.errorMetaRequestId = action.meta.requestId;
        }
    }
});

export default municipalitySlice.reducer;



