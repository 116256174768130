import Start from '../../Pages/1_Start/Start';
import ObstacleInfo from '../../Pages/2_ObstacleInformation/ObstacleInformation';
import ContactInformation from '../../Pages/3_ContactInformation/ContactInformation';
import Summary from '../../Pages/4_Summary/Summary';
import End from '../../Pages/5_End/End';
import NotFound from '../../Pages/404/NotFound404';
import Backoffice from '../../Pages/Backoffice/Backoffice';
import GDPR from '../../Pages/GDPR/GDPR'
import Error from '../../Pages/Error/Error';
import ErrorBo from '../../Pages/Error/ErrorBo';

import { Route, Routes } from "react-router-dom";
import {LoginBo, Register} from "../../Pages/Login/LoginBo";
const PageRoutes = () => {
    return(
        <Routes>
          <Route path='/' element={ <Start /> }/>
          <Route path='/obstacle' element={ <ObstacleInfo /> }/>
          <Route path='/contact' element={ <ContactInformation /> }/>
          <Route path='/summary' element={ <Summary /> }/>
          <Route path='/thankyou' element={ <End /> }/>
          <Route path='/login' element={ <LoginBo /> }/>
          <Route path={'/register'} element={<Register />} />
          <Route path='/backoffice' element={ <Backoffice /> }/>
          <Route path='/gdpr' element={ <GDPR /> }/>
          <Route path='/somethingwentwrong' element={ <Error /> }/>
          <Route path='/somethingwentwrongbackoffice' element={ <ErrorBo /> }/>
          <Route path='/*' element={ <NotFound /> }/>
        </Routes>
    );
}

export default PageRoutes;