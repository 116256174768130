import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { DateTime } from "luxon";


const initialState = {
    municipalityId: 0,
    issuesPerPage: 0,
    issueId: 0,
    obstacleId: 0,
    date: {},
};

export const GetMinMaxDateFromBo = createAsyncThunk('backofficeData/getDateFromBo',  async ( issues ) => {
    let dateArr = [];
    issues.forEach(issue => {
      dateArr.push(DateTime.fromSQL(issue.dateCreated));  
    });
    const minIssueDate = DateTime.min(...dateArr).toFormat('yyyy MM dd');
    const maxIssueDate = DateTime.max(...dateArr).toFormat('yyyy MM dd');
    const returnDates = { min: minIssueDate.replace(/ /g,"-"), max: maxIssueDate.replace(/ /g,"-")};
    return await returnDates;
});

const backofficeDataSlice = createSlice({
    name: "backofficeData",
    initialState,
    reducers: {
        storeBoMunicipalityId: ( state, municipalityId ) => {
            state.municipalityId = municipalityId.payload;
        },
        storeBoIssuesPerPage: ( state, number ) => {
            state.issuesPerPage = number.payload;
        },
        storeBoIssueId: ( state, issueId ) => {
            state.issueId = issueId.payload;
        },
        storeBoDate: ( state, date ) => {
            state.date = date.payload;
        },
        storeBoObstacleId: ( state, date ) => {
            state.obstacleId = date.payload;
        },
    },
    extraReducers: {
        [GetMinMaxDateFromBo.fulfilled]: ( state, action ) => {
            // console.log(action);
            state.date = action.payload;
        },
    }
});

export const { 
               storeBoMunicipalityId, 
               storeBoIssuesPerPage, 
               storeBoIssueId, 
    storeBoObstacleId,
               storeBoDate,  
             } 
               = backofficeDataSlice.actions;

export default backofficeDataSlice.reducer;