import '../Error/ErrorBo.css';

import * as Constants from '../../constants';
import backOfficeLogo from '../../Assets/backOfficeLogo.svg';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const Error = () => {

    const issue = useSelector((state) => state.issue);
    const [issueError, setIssueError] = useState(false);

    useEffect(() => {
        if( issue.errorMessage !== "") { setIssueError(true); }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return(
        <div className='error-bo-main-container'>
            <div className='error-bo-logo-container'>
                <img src={ backOfficeLogo } alt={ Constants.BackOffice.imgAltText } className="error-bo-logo" />
            </div>
            <div className="error-bo-text-container">
                <h1 className='error-bo-main-header'>{Constants.ErrorMessages.mainHeader}</h1>
                <p className='error-bo-p'>{ Constants.ErrorMessages.information }</p>
                <p className='error-bo-p'>{ Constants.ErrorMessages.instruction }</p> <br />
                <h1 className='error-bo-sub-header'>{ Constants.ErrorMessages.errorInformationHeader }</h1>

                { issueError ?
                <div className="error-bo-code-container">
                    <label className='error-code-label'>{ Constants.ErrorMessages.errorMessage }</label>
                    <code className='error-code'>{ issue.errorMessage }</code> 
                    <label className='error-code-label'>{ Constants.ErrorMessages.errorType }</label>
                    <code className='error-code'>{ issue.errorType }</code>
                </div> :
                null
                }

            </div>
            {/* <Link to={ '/contact' }>

                <div className="error-bo-btn-container">
                    <div className="error-bo-btn-icon-container">{ <BackStep size={ size }/>}</div>
                    <div className="error-bo-btn-text">{ Constants.GDPR.button}</div>
                </div>
            </Link> */}
        </div>
    );
}

export default Error;