import '../5_End/End.css';

import DHRLogo from '../../Components/Btns, icons & logos/DHRLogos/DHRLogo';
import BtnLgInv from '../../Components/Btns, icons & logos/BtnLgInv/BtnLgInv';
import * as Constants from '../../constants';
import { BackStep, Globe } from '../../icons';
import { IconSize } from '../../Services/MediaQueryServices';
import { LgIconSize } from '../../Services/MediaQueryServices';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const End = () => {

let size = IconSize();
const lgSize = LgIconSize(size);
const navigate = useNavigate();
const formsData = useSelector((state) => state.formsData);

const whenClicked = () => {
    // eslint-disable-next-line
    setTimeout("location.reload(true);",500);
    // document.location.reload()
}

useEffect(() => {
    if (formsData.errorMessage !== ""){ navigate('/somethingwentwrong'); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formsData.errorMessage]);

    return(
        <div className='end-main-container'>
            <div className='end-logo-container'>
                <DHRLogo />
            </div>
            <div className="end-text-container">
                <h1>{Constants.End.header}</h1>
                <p className='end-p'>{ Constants.End.confirmation }</p>
                <p className='end-p'>{ Constants.End.information }</p>
                <p className="end-p">{ Constants.End.instruction}</p>
            </div>
            <div className="end-link-container">
                <Link to="/" className='end-link-btn'>
                    <BtnLgInv text={ Constants.End.newIssue } whenClicked={ whenClicked }
                            icon={ <BackStep color="var(--main-color)" size={ lgSize } /> } />
                </Link>
                <a className='end-link-btn' href={ Constants.End.linkHref } tabIndex="0">
                    <BtnLgInv text={ Constants.End.linkText }
                            icon={ <Globe color="var(--main-color)" size={ lgSize } /> } />
                </a>
            </div>
        </div>
    );
}

export default End;