import '../App/App.css';

import BtnLg from '../Components/Btns, icons & logos/BtnLg/BtnLg';
import HeaderLogoBackstep from '../Components/Btns, icons & logos//HeaderLogoBackstep/HeaderLogoBackstep';
import PageRoutes from '../Components/Others/PageRoutes';

import { GetNextPage } from '../Services/RouterServices';
import * as LocationService from '../Services/LocationDependantServices';

import { GetMunicipalities } from '../Features/Municipality/MunicipalitySlice';
import { GetObstacleTypes } from '../Features/ObstacleType/ObstacleTypeSlice';
import { resetForms, SendData } from '../Features/FormsData/FormsDataSlice';

import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VerifyReCaptcha } from '../Features/ReCaptcha/ReCaptchaSlice';

const App = () => {
  
  const dataSummary = useSelector((state) => state.formsData);
  const municipality = useSelector((state) => state.municipality);
  const obstacle = useSelector((state) => state.obstacleType);
  const issue = useSelector((state) => state.issue);
  const { token } = useSelector((state) => state.loginData);
  const reCaptcha = useSelector((state) => state.reCaptcha)
  const [displayMainBtn, setDisplayMainBtn] = useState(true);
  const [disableMainBtn, setDisableMainBtn] = useState(false);
  const header = LocationService.GetHeader();
  const text = LocationService.TextBtnLG();
  const icon = LocationService.IconBtnLG();
  const displayHeaderComponent = LocationService.DisplayHeaderLogoBackstep();
  const navigate = useNavigate();
  
  const whenClicked = () => {
    console.log("TOKEN APP", reCaptcha.reCaptchaToken);
    if (window.location.pathname === "/summary") { 
                console.log("SKICKA DATA KLICK")
                dispatch(SendData(dataSummary));
                dispatch(resetForms());
               const response =  dispatch(VerifyReCaptcha( reCaptcha.reCaptchaToken ))
               console.log("SVAR PÅ RECAPTCHA", response);
    }
  } 
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(GetMunicipalities());
    dispatch(GetObstacleTypes());
    if (municipality.errorMessage !== ""){ navigate('/somethingwentwrong'); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("MUNICIPALITY", municipality.errorMessage)
    if (token !== "" && issue.errorMessage !== "")
    { navigate('/somethingwentwrongbackoffice'); }
    else if (municipality.errorMessage !== "" || obstacle.errorMessage !== "" )
    { navigate('/somethingwentwrong'); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [municipality.errorMessage, issue.errorMessage]);

  const [nextPage, setNextPage] = useState('');
  const location = useLocation();

  useEffect( () => {
    setNextPage(GetNextPage());
    setDisplayMainBtn(LocationService.DisplayMainBtn());
    setDisableMainBtn(LocationService.DisableMainBtn(reCaptcha.hasToken));
  },[location, reCaptcha.hasToken]);

  return (
      <div className="app-view-container">
        <div className='app-header-container'>
          { displayHeaderComponent ? 
            <HeaderLogoBackstep header={ header } /> : null }
        </div>
        <PageRoutes/>
        { displayMainBtn ?
        <div className='app-main-btn-container'>
            <BtnLg text={ text } icon={ icon } nextPage={nextPage} whenClicked={ whenClicked } disableMainBtn={disableMainBtn} />
        </div>
         : null }
      </div>
  );
}

export default App;
