import { Checkmark } from "../../../icons";

const Checkbox = ( { name, label, checked, whenChanged }) => {
    return(
        <label htmlFor={ name} className="container-label">{ label }
            <input name={ name} id={ name} type="checkbox" checked={ checked } onChange={ whenChanged } />
            <span className="checkmark"></span>
            { checked ?
                <div className='checked-icon-container'>
                    <Checkmark color="var(--main-color)" size="1.2rem" className="checkmark" />
                </div> : null
            }
        </label>
    );
}

export default Checkbox