export const ArrowDown = ( props ) => {
    return(
        <svg id={props.name}
        aria-hidden="true" 
        className={props.className} 
        fill={props.color} 
        height={props.size} 
        width={props.size}
        viewBox="0 0 451.847 451.847" 
        xmlns="http://www.w3.org/2000/svg" 
        data-name={props.name}>
       <title>{props.name}</title>
                
            <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
        c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
        c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
        </svg>
    );
}

export const ArrowUp = ( props ) => {
    return(
        <svg id={props.name}
        aria-hidden="true" 
        className={props.className} 
        fill={props.color} 
        height={props.size} 
        width={props.size}
        viewBox="0 0 240.835 240.835" 
        xmlns="http://www.w3.org/2000/svg" 
        data-name={props.name}>
       <title>{props.name}</title>
                
            <path stroke="black" strokeWidth="1"  d="M129.007,57.819c-4.68-4.68-12.499-4.68-17.191,0L3.555,165.803c-4.74,4.74-4.74,12.427,0,17.155
		c4.74,4.74,12.439,4.74,17.179,0l99.683-99.406l99.671,99.418c4.752,4.74,12.439,4.74,17.191,0c4.74-4.74,4.74-12.427,0-17.155
		L129.007,57.819z"/>
        </svg>
    );
}

export const BackStep = ( props ) => {
    return(
        <svg id={props.name}
        aria-hidden="true" 
        className={props.className} 
        fill={props.color} 
        height={props.size} 
        width={props.size}
        viewBox="0 0 24 24" 
        xmlns="http://www.w3.org/2000/svg" 
        data-name={props.name}>
       <title>{props.name}</title>
                
            <path d="m22 11h-17.586l5.293-5.293a1 1 0 1 0 -1.414-1.414l-7 7a1 1 0 0 0 0 1.414l7 7a1 1 0 0 0 1.414-1.414l-5.293-5.293h17.586a1 1 0 0 0 0-2z"/>
        </svg>
    );
}

export const Checkmark = ( props ) => {
    return(
        <svg id={props.name}
        aria-hidden="true"
        className={props.className} 
        fill={props.color} 
        height={props.size} 
        width={props.size}
        viewBox="0 0 24 24" 
        xmlns="http://www.w3.org/2000/svg" 
        data-name={props.name}>
       <title>{props.name}</title>
                
            <path d="m20.7071 6.29289c.3905.39053.3905 1.02369 0 1.41422l-10 9.99999c-.3905.3905-1.02368.3905-1.41421 0l-5-5c-.39052-.3905-.39052-1.0237 0-1.4142.39053-.3905 1.02369-.3905 1.41422 0l4.29289 4.2929 9.2929-9.29291c.3905-.39052 1.0237-.39052 1.4142 0z"/>
        </svg>
    );
}

export const ClearOrClose = ( props ) => {
    return(
        <svg id={props.name}
        aria-hidden="true"
        className={props.className} 
        fill={props.color} 
        height={props.size} 
        width={props.size}
        viewBox="0 0 320.591 320.59" 
        xmlns="http://www.w3.org/2000/svg" 
        data-name={props.name}>
       <title>{props.name}</title>
                
            <path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z"/><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z"/>
        </svg>
    );
}

export const Export = ( props ) => {
    return(
        <svg id={props.name}
        aria-hidden="true"
        className={props.className} 
        fill={props.color} 
        height={props.size} 
        width={props.size}
        viewBox="0 0 20 20" 
        xmlns="http://www.w3.org/2000/svg" 
        data-name={props.name}>
       <title>{props.name}</title>
                
            <path d="M10 9V6L14 10L10 14V11H6V9H10ZM10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0ZM10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10C2 14.42 5.58 18 10 18Z"/>
        </svg>
    );
}

export const ForwardStep = ( props ) => {
    return(
        <svg id={props.name}
        aria-hidden="true" 
        className={props.className} 
        fill={props.color} 
        height={props.size} 
        width={props.size}
        viewBox="0 0 24 24" 
        xmlns="http://www.w3.org/2000/svg" 
        data-name={props.name}>
       <title>{props.name}</title>
                
            <path stroke="black" strokeWidth="1.5"  d="m22.707 11.293-7-7a1 1 0 0 0 -1.414 1.414l5.293 5.293h-17.586a1 1 0 0 0 0 2h17.586l-5.293 5.293a1 1 0 1 0 1.414 1.414l7-7a1 1 0 0 0 0-1.414z"/>
        </svg>
    );
}

export const Globe = ( props ) => {
    return(
        <svg id={props.name}
        aria-hidden="true" 
        className={props.className} 
        fill={props.color} 
        height={props.size} 
        width={props.size}
        viewBox="0 0 512 512" 
        xmlns="http://www.w3.org/2000/svg" 
        data-name={props.name}>
       <title>{props.name}</title>
                
            <path d="M256,0C114.844,0,0,114.844,0,256s114.844,256,256,256s256-114.844,256-256S397.156,0,256,0z M256,469.333
			c-58.116,0-110.82-23.418-149.333-61.238v-34.762c0-10.146,4.542-16.677,9.792-24.24C121.865,341.313,128,332.49,128,320
			c0-20.344-18.365-22.406-28.24-23.51c-7.063-0.792-13.729-1.542-17.552-5.365c-2.896-2.896-5.792-8.156-8.854-13.719
			c-6.081-11.064-14.324-25.695-30.017-34.656C50.236,131.296,142.837,42.667,256,42.667c5.855,0,11.611,0.414,17.349,0.879
			c-1.168,0.767-2.599,1.288-3.557,2.246c-2.073,2.073-3.208,4.917-3.125,7.854c0.094,2.927,1.385,5.698,3.573,7.656
			c3.833,3.406,4.573,5.125,4.719,5.125c-0.24,0.51-2.198,3.854-13.115,9.396c-18.021,9.135-38.833,27.833-41.927,47.292
			c-1.417,8.833,1.146,17.031,7.208,23.094c2,2,4.708,3.125,7.542,3.125c14.813,0,26.26-5.479,37.333-10.771
			C283.365,133.135,294.104,128,309.333,128c41.865,0,74.667,9.375,74.667,21.333c0,4.385-1.365,5.729-1.885,6.229
			c-5.24,5.156-23.083,4.823-38.771,4.583c-4.156-0.073-8.406-0.146-12.677-0.146c-14.479,0-18.969-2.115-24.167-4.573
			c-6.052-2.854-12.906-6.094-29.167-6.094c-17.583,0-50.26,3.177-71.542,24.458c-17.406,17.396-15.563,38.208-14.354,51.969
			c0.281,3.167,0.563,6.167,0.563,8.906c0,21.01,21.469,32,42.667,32c32.604,0,60.792,6.083,64,10.667
			c0,11.938,3.552,20.094,6.406,26.635c2.375,5.469,4.26,9.781,4.26,16.031c0,4.833-0.792,5.865-2.927,8.615
			c-4.073,5.292-7.74,11.052-7.74,23.385c0,22.448,21.615,47.073,24.073,49.813c2.052,2.271,4.948,3.521,7.927,3.521
			c0.885,0,1.771-0.104,2.646-0.333c6.281-1.615,61.354-16.771,61.354-53c0-11.354,3.531-14.417,8.885-19.063
			c5.25-4.563,12.448-10.802,12.448-23.604c0-8.552,15.177-30.625,29.24-46.177c1.99-2.198,2.979-5.135,2.719-8.094
			c-0.26-2.958-1.74-5.677-4.083-7.49c-8.292-6.427-31.188-27.354-38.854-47.656c4.344,2.271,9.781,5.969,14.104,10.292
			c3.552,3.573,8.313,5.281,13.729,5.063c8.639-0.493,18.902-7.319,28.628-15.833c4.975,18.046,7.852,36.956,7.852,56.563
			C469.333,373.635,373.635,469.333,256,469.333z"/>
        </svg>
    );
}

export const LogOut = ( props ) => {
    return(
        <svg id={props.name}
        aria-hidden="true" 
        className={props.className} 
        fill={props.color} 
        height={props.size} 
        width={props.size}
        viewBox="0 0 19 20" 
        xmlns="http://www.w3.org/2000/svg" 
        data-name={props.name}>
       <title>{props.name}</title>
                
            <path d="M3 16H5V18H17V2H5V4H3V1C3 0.734784 3.10536 0.48043 3.29289 0.292893C3.48043 0.105357 3.73478 0 4 0H18C18.2652 0 18.5196 0.105357 18.7071 0.292893C18.8946 0.48043 19 0.734784 19 1V19C19 19.2652 18.8946 19.5196 18.7071 19.7071C18.5196 19.8946 18.2652 20 18 20H4C3.73478 20 3.48043 19.8946 3.29289 19.7071C3.10536 19.5196 3 19.2652 3 19V16ZM5 9H12V11H5V14L0 10L5 6V9Z"/>
        </svg>
    );
}

export const MagnifyingGlass = ( props ) => {
    return(
        <svg id={props.name}
        aria-hidden="true" 
        className={props.className} 
        fill={props.color} 
        height={props.size} 
        width={props.size}
        viewBox="0 0 461.516 461.516" 
        xmlns="http://www.w3.org/2000/svg" 
        data-name={props.name}>
       <title>{props.name}</title>
                
            <path d="m185.746 371.332c41.251.001 81.322-13.762 113.866-39.11l122.778 122.778c9.172 8.858 23.787 8.604 32.645-.568 8.641-8.947 8.641-23.131 0-32.077l-122.778-122.778c62.899-80.968 48.252-197.595-32.716-260.494s-197.594-48.252-260.493 32.716-48.252 197.595 32.716 260.494c32.597 25.323 72.704 39.06 113.982 39.039zm-98.651-284.273c54.484-54.485 142.82-54.486 197.305-.002s54.486 142.82.002 197.305-142.82 54.486-197.305.002c-.001-.001-.001-.001-.002-.002-54.484-54.087-54.805-142.101-.718-196.585.239-.24.478-.479.718-.718z"/>
        </svg>
    );
}

export const Padlock = ( props ) => {
    return(
        <svg id={props.name}
        aria-hidden="true" 
        className={props.className} 
        fill={props.color} 
        height={props.size} 
        width={props.size}
        viewBox="0 0 24 24" 
        xmlns="http://www.w3.org/2000/svg" 
        data-name={props.name}>
       <title>{props.name}</title>
                
            <path stroke={props.stroke} strokeWidth="1"  d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z"/>
        </svg>
    );
}

export const Photo = ( props ) => {
    return(
        <svg id={props.name}
        aria-hidden="true" 
        className={props.className} 
        fill={props.color} 
        height={props.size} 
        width={props.size}
        viewBox="0 0 550.801 550.84" 
        xmlns="http://www.w3.org/2000/svg" 
        data-name={props.name}>
       <title>{props.name}</title>
                
            <path d="M515.828,61.201H34.972C15.659,61.201,0,76.859,0,96.172v358.458C0,473.942,15.659,489.6,34.972,489.6h480.856
		c19.314,0,34.973-15.658,34.973-34.971V96.172C550.801,76.859,535.143,61.201,515.828,61.201z M515.828,96.172V350.51l-68.92-62.66
		c-10.359-9.416-26.289-9.04-36.186,0.866l-69.752,69.741L203.438,194.179c-10.396-12.415-29.438-12.537-39.99-0.271L34.972,343.219
		V96.172H515.828z M367.201,187.972c0-26.561,21.523-48.086,48.084-48.086c26.562,0,48.086,21.525,48.086,48.086
		c0,26.561-21.523,48.085-48.086,48.085C388.725,236.058,367.201,214.533,367.201,187.972z"/>
        </svg>
    );
}

export const Send = ( props ) => {
    return(
        <svg id={props.name}
        aria-hidden="true" 
        className={props.className} 
        fill={props.color} 
        height={props.size} 
        width={props.size}
        viewBox="0 0 29 29" 
        xmlns="http://www.w3.org/2000/svg" 
        data-name={props.name}>
       <title>{props.name}</title>
                
            <path stroke="black" strokeWidth="1"  d="m0.724276 10.2742C0.0017757 9.98376 0.0102758 9.55167 0.772442 9.29809L27.8109 0.285256C28.5604 0.0359229 28.9896 0.455256 28.7799 1.18909L21.0534 28.2276C20.8409 28.977 20.3805 29.011 20.0377 28.3268L13.5834 15.4167L0.724276 10.2742ZM7.65178 9.99084L15.6361 13.1854L19.9428 21.8016L24.9663 4.22076L7.65036 9.99084H7.65178z"/>
        </svg>
    );
}



