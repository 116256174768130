import '../GDPR/GDPR.css';

import DHRLogo from '../../Components/Btns, icons & logos/DHRLogos/DHRLogo';
import * as Constants from '../../constants';
import { IconSize } from '../../Services/MediaQueryServices';
import { BackStep } from '../../icons';

import { Link } from 'react-router-dom';

const GDPR = () => {

    const size = IconSize();
    return(
        <div className='gdpr-main-container'>
            <div className='gdpr-logo-container'>
                <DHRLogo />
            </div>
            <div className="gdpr-text-container">
                <h1 className='gdpr-header'>{Constants.GDPR.header}</h1>
                <p className='gdpr-p'>{ Constants.GDPR.paragraph1 }<a href='mailto:jonas.andersson@toxic.se'>{Constants.Misc.appContactMail}</a></p> <br />
                <p className='gdpr-p'>{ Constants.GDPR.paragraph2 }<a href={Constants.URLs.DHRIntegrity}>{Constants.GDPR.linkText}</a></p> <br />
            </div>
            <Link to={ '/contact' }>

                <div className="gdpr-btn-container">
                    <div className="gdpr-btn-icon-container">{ <BackStep size={ size }/>}</div>
                    <div className="gdpr-btn-text">{ Constants.GDPR.button}</div>
                </div>
            </Link>
        </div>
    );
}

export default GDPR;