import dhrlogo from '../../../Assets/DHR_yellow.png';

import * as Constants from '../../../constants'; 


const DHRLogo = () =>{
    return(
        <img src={dhrlogo} alt={Constants.Misc.logoAltText} />
    );
}

export default DHRLogo;