
import '../Searchbar/SearchBar.css';

import { MagnifyingGlass, ClearOrClose } from '../../../icons';
import { IconSize } from '../../../Services/MediaQueryServices';
import { storeObstacleMunicipalityId, storeObstacleMunicipalityShortName, storeObstacleMunicipalityLongName, resetObstacleMunicipality } from '../../../Features/FormsData/FormsDataSlice';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

//Code and inspiration from: https://www.youtube.com/watch?v=x7niho285qs&t=415s

const SearchBar = ({ name, id, placeholder, payload, data, title, label }) => {
    const dispatch = useDispatch();
    const [filteredData, setFilteredData] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const size = IconSize();

    const HandleFilter = (event) => {
        const searchInput = event.target.value;
        setInputValue(searchInput);
        console.log(searchInput + ' ' + inputValue);
        const filter = data.filter((value) => {
            return value.shortName.toLowerCase().startsWith(searchInput.toLowerCase());
        });
        searchInput === "" ? setFilteredData([]) : setFilteredData(filter);

    };

    const ClearInput = () => {
        dispatch(resetObstacleMunicipality());
        setInputValue("");
        setFilteredData([]);
    }

    const SetChosenMunicipality = (municipalityId, municipalityShortName, municipalityLongName) => {
        setInputValue(municipalityShortName);
        dispatch(storeObstacleMunicipalityId(municipalityId));
        dispatch(storeObstacleMunicipalityShortName(municipalityShortName));
        dispatch(storeObstacleMunicipalityLongName(municipalityLongName));
        setFilteredData([]);
    }


    return (
        <div className="search-bar">
            <div className="search">
                <label htmlFor={name}>{label}</label>
                <div className="input-icon">
                    {payload === "" ?
                        <input name={name} id={id} type="text" className="form-input" title={title}
                            value={inputValue} placeholder={placeholder} pattern='[A-z]' onChange={HandleFilter} required /> :
                        <input name={name} id={id} type="text" className="form-input approved-input" value={payload} title={title} />
                    }
                    <span className="input-icon-container" onClick={ClearInput}>
                        {filteredData.length === 0 && payload === "" ?
                            <MagnifyingGlass name="Search" color="var(--dark-color)" size={size} />
                            : <ClearOrClose name="clear-btn" colour="var(--dark-color)" size={size} />}
                    </span>
                </div>
            </div>
            {filteredData.length !== 0 && (
                <div className="data-result">
                    {filteredData.map((value) => {
                        return <button className='data-item' key={value.id}
                            onClick={() => SetChosenMunicipality(value.id, value.shortName, value.longName)}>{value.shortName}</button>
                    })}
                </div>
            )}
        </div>
    );
}

export default SearchBar;