import * as Constants from '../../constants';
import { useSelector } from 'react-redux';

const SummaryDataDisplay = ( { header, content} ) => {

    const { photo } = useSelector((state) => state.formsData);

    if (content === ""){ content = Constants.Summary.noInfo } ;
    return(
        <>
            <p style={{color:"var(--main-color)", fontWeight:"var(--text-body-header-weight)"}}>{ header }</p>
            { header === Constants.Summary.photo && photo !== "" ?
            <img src={ content } alt="Not found" width={ "100px" } height={ "100px" } /> :
            <p>{ content }</p>
            }
        </>
    );
}

export default SummaryDataDisplay;