import '../HeaderLogoBackstep/HeaderLogoBackstep.css'
import Backstep from "../Backstep/Backstep";
import DHRLogo from '../DHRLogos/DHRLogo';
import { GetPreviousPage } from '../../../Services/RouterServices';
import { useState, useEffect } from "react";
import { Link, useLocation} from 'react-router-dom';

const HeaderLogoBackstep = ( props ) => {

  const [prevPage, setPrevPage] = useState('');
  const location = useLocation();

  useEffect( () => {
    setPrevPage(GetPreviousPage());
  },[location]);

    return(
        <div className="header-logo-backstep-main-container">
            <Link to={ prevPage }>
                <Backstep />  
            </Link>

            <div className='header-logo-backstep-logo-container'>
                <DHRLogo />
            </div>
            
            <div className='header-logo-backstep-header-container'>
                    <h1>{ props.header }</h1>
            </div>
        </div>
        
    );
}

export default HeaderLogoBackstep;