import '../MUIdataTable/MUIdataTable.css';
//MUI imports for datatable
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { svSE } from '@mui/material/locale';
// import {  grey } from '@mui/material/colors';
// import { dark } from '@mui/material/styles/createPalette';
// import { width } from '@mui/system';

import * as Constants from '../../../constants';

import { useState } from 'react';
import {Button} from "@mui/material";
import axios from "axios";

const MUIdataTable = ( { issues, selectRowsPerPage } ) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rows = [];

  // const textColor = grey[900];

  const theme = createTheme(
    {
    typography: {
      allVariants: {
        color: "var(--dark-color)"
      },
    },
  },
  svSE,
  );

  const createData = (
    name,
    email,
    phoneNo,
    sentTo,
    location,
    obstacle,
    issueId,
    date,
    imgUrl,
    description,
    processed
  ) => {
    return {
      name,
      email,
      phoneNo,
      sentTo,
      location,
      obstacle,
      issueId,
      date,
      processed,
      extra: [
        {
          description: description,
          url: imgUrl,
        },
      ],
    };
  };

  issues.forEach(issue => { 
    var image = "";
    if(issue.image !== null){ image = issue.image.url }
    const row = createData( issue.name,
                            issue.email,
                            issue.phoneNumber,
                            issue.municipality.longName,
                            issue.location,
                            issue.obstacle.name,
                            issue.id,
                            issue.dateCreated,
                            image,
                            issue.description,
                            issue.processed
                            )
    rows.push(row);
  });
  const handleSort = () => {

    setSortOrder(sortOrder === 'asc' || sortOrder === "" ? 'desc' : 'asc');
  };
  const [sortOrder, setSortOrder] = useState(''); // 'asc' or 'desc'

  const handleCheckboxChange = async (currentRow, event) => {
    const devUrl = "/dbcontext/processissue";
    const prodUrl = Constants.URLs.base + devUrl;
    let url = "";
    process.env.NODE_ENV === 'development' ? url = devUrl : url = prodUrl;
    if (event.target.checked) {
      try {
        // Send POST request to /processIssue with row data as body
        const response = await axios.post(url, {"issueId": currentRow.issueId, "processed": false});
        console.log("Successfully processed issue: ", response.data);
        window.location.reload();
      } catch (error) {
        console.error("Error processing issue: ", error);
      }
    }
  };
  const convertUtcToLocal = (utcTimeString) => {
    // Parse the UTC time string into a Date object
    const utcDate = new Date(utcTimeString + ' UTC');

    // Get the local time components
    const year = utcDate.getFullYear();
    const month = utcDate.getMonth();
    const date = utcDate.getDate();
    const hours = utcDate.getHours();
    const minutes = utcDate.getMinutes();
    const seconds = utcDate.getSeconds();

    // Create a formatted local time string
    const localTimeString = `${year}-${String(month + 1).padStart(2, '0')}-${String(date).padStart(2, '0')} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    return localTimeString;
  };
  const Row = ({ row }) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              className="bo-iconbutton"
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="center" component="th" scope="row">{row.issueId}</TableCell>
          <TableCell align="left">{row.sentTo}</TableCell>
          <TableCell align="left">{row.location}</TableCell>
          <TableCell align="left">{row.obstacle}</TableCell>
          <TableCell align="left">{convertUtcToLocal(row.date)}</TableCell>
          <TableCell align="left">      <input
              type="checkbox"
              aria-label={row.processed}
              checked={row.processed}
              onChange={(e) => handleCheckboxChange(row, e)}
          /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div" sx={{fontWeight:'700'}}>
                  {Constants.BackOffice.extra + row.issueId }
                </Typography>
                <Table size="small" aria-label={Constants.BackOffice.extra}>
                  <TableHead>
                    <TableRow>
                    <TableCell width="15%"></TableCell>
                      <TableCell width="15%" sx={{fontWeight:'700'}}>{Constants.BackOffice.description}</TableCell>
                      
                      <TableCell sx={{fontWeight:'700'}} width="15%">{Constants.BackOffice.image}</TableCell>
                      <TableCell align="left" width="15%" sx={{fontWeight:'700'}}>{Constants.BackOffice.name}</TableCell>
                      <TableCell align="left" width="15%" sx={{fontWeight:'700'}}>{Constants.BackOffice.email}</TableCell>
                      <TableCell align="left" width="20%" sx={{fontWeight:'700'}}>{Constants.BackOffice.phone}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.extra.map((extraRow) => (
                      <TableRow key={extraRow.url}>
                        <TableCell></TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ width: 100 }}>
                          {extraRow.description}
                        </TableCell>
                        <TableCell>
                          {" "}
                          { extraRow.url === "" ? "Ingen bild inskickad" :
                          <div className='bo-row-img-container'>
                            <img src={extraRow.url} alt={ Constants.BackOffice.obstacleImgAltText } id="bo-row-img" />
                          </div>
                          }
                        </TableCell>
                        <TableCell component="th" scope="row">{row.name}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.phoneNo}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
    const theme = useTheme();

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
        </IconButton>
      </Box>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //#region PropTypes
  // Row.propTypes = {
  //   row: PropTypes.shape({
  //     name: PropTypes.string.isRequired,
  //     email: PropTypes.string.isRequired,
  //     phone: PropTypes.string.isRequired,
  //     extra: PropTypes.arrayOf(
  //       PropTypes.shape({
  //         description: PropTypes.string.isRequired,
  //         imgUrl: PropTypes.string.isRequired,
  //       }),
  //     ).isRequired,
  //     name: PropTypes.string.isRequired,
  //     price: PropTypes.number.isRequired,
  //     protein: PropTypes.number.isRequired,
  //   }).isRequired,
  // };
  //#endregion

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
  function renderStuff() {
    return (<>{(rowsPerPage > 0
            ? rows.sort((a, b) => {
              if (sortOrder === 'asc') {
                return a.obstacle.localeCompare(b.obstacle);
              } else if (sortOrder === 'desc') {
                return b.obstacle.localeCompare(a.obstacle);
              }
              return a -b;
            }).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            )
            : rows.sort((a, b) => {
              if (sortOrder === 'asc') {
                return a.obstacle.localeCompare(b.obstacle);
              } else if (sortOrder === 'desc') {
                return b.obstacle.localeCompare(a.obstacle);
              }
              return a -b;
            })
    ).map((row) => (
        <Row key={row.issueId} row={row} />
    ))}</>)
  }

  return (
      <div className="data-table-component-container">
      <ThemeProvider theme={ theme }>
         <TablePagination 
            color="var(--dark-color)"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: { 'aria-label': 'Rader per sida' },
              native: true,
              }}
          />
        </ThemeProvider>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 300 }} aria-label="collapsible table" className='bo-data-table'>
            <TableHead>
              <TableRow>
              <TableCell align="left" width="5%" sx={{fontWeight:'700'}}>{Constants.BackOffice.moreInfo}</TableCell>
                <TableCell align="center" width="15%" sx={{fontWeight:'700'}}>{Constants.BackOffice.issueId}</TableCell>
                <TableCell align="left" width="15%" sx={{fontWeight:'700'}}>{Constants.BackOffice.sentTo}</TableCell>
                <TableCell align="left" width="15%" sx={{fontWeight:'700'}}>{Constants.BackOffice.location}</TableCell>
                <TableCell align="left" width="15%" sx={{fontWeight:'700'}}>{Constants.BackOffice.obstacle}  <Button onClick={handleSort}>{sortOrder === 'asc' ? '↑' : '↓'}</Button></TableCell>
                <TableCell align="left" width="25%" sx={{fontWeight:'700'}}>{Constants.BackOffice.date}</TableCell>
                <TableCell align="left" width="20%" sx={{fontWeight:'700'}}>Hanterad</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderStuff()}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
         </Table>
        </TableContainer>
        <ThemeProvider theme={ theme }>
         <TablePagination 
            color="var(--dark-color)"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: { 'aria-label': 'Rader per sida' },
              native: true,
              }}
          />
        </ThemeProvider>
      </div>
  );
}

export default MUIdataTable