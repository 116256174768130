import * as Constants from '../../constants';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    obstacleTypes: [],
    isLoading: true,

    errorMessage: "",
    errorType:"",
    errorMetaRequestId: "",
};

export const GetObstacleTypes = createAsyncThunk('obstacleTypes/getObstacleTypes',  async () => {
    const devUrl = Constants.URLs.getObstacles;
    const prodUrl = Constants.URLs.base + devUrl;
    let url = "";
    process.env.NODE_ENV === 'development' ? url = devUrl : url = prodUrl;
    return await axios.get(url).then(response =>{
        console.log(response)
        return response.data;
    }).catch((error) => {
        console.log("obstacleFEL",error)
        throw error;
    })
})

const obstacleTypeSlice = createSlice({
    name: 'obstacleType',
    initialState,
    extraReducers: {
        [GetObstacleTypes.pending]: ( state ) => {
            state.isLoading = true;
        },
        [GetObstacleTypes.fulfilled]: ( state, action ) => {
            // console.log(action);
            state.isLoading = false;
            state.obstacleTypes = action.payload;
        },
        [GetObstacleTypes.rejected]: ( state, action ) => {
            state.isLoading = false;
            state.errorMessage = action.error.message;
            state.errorType = action.type;
            state.errorMetaRequestId = action.meta.requestId;
        }
    }
});

export default obstacleTypeSlice.reducer;
