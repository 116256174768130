import '../4_Summary/Summary.css';

import * as Constants from '../../constants';
import SummaryDataDisplay from '../../Components/Others/SummaryDataDisplay';
import SummaryContactDisplay from '../../Components/Others/SummaryContactDisplay';
import { storeHasToken, storeReCaptchaToken } from '../../Features/ReCaptcha/ReCaptchaSlice';

import { ContactFormApproved } from '../../Services/FormsServices';

import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import ReCAPTCHA from 'react-google-recaptcha';

const Summary = () => {
    const formsData = useSelector((state) => state.formsData);
    const navigate = useNavigate();
    const captchaRef = useRef(null)
    const dispatch = useDispatch()

    useEffect(() => {
    if(!ContactFormApproved( formsData )){ navigate('/contact');  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    var gdprData = "";
    var wantFeedbackData = "";
    
    formsData.wantFeedback ===  false || formsData.wantFeedback ===  false ? wantFeedbackData = Constants.Contact.no : wantFeedbackData = Constants.Contact.yes;
    formsData.approveGDPR === false || formsData.approveGDPR ===  false ? gdprData = Constants.Contact.no : gdprData = Constants.Contact.yes;
    

    const collectedData = [
            { id: 1, header: Constants.Summary.place, content: formsData.place },
            { id: 2, header: Constants.Summary.desc, content: formsData.description },
            { id: 5, header: Constants.Summary.type, content: formsData.obstacleName },
            { id: 6, header: Constants.Summary.sendTo, content: formsData.municipalityLongName },
            { id: 7, header: Constants.Summary.wantFeedback, content: wantFeedbackData },
            { id: 8, header: Constants.Summary.approvedGDPR, content: gdprData },
            { id: 9, header: Constants.Summary.photo, content: formsData.photo },
        ];

    const handleRecaptchaCheck = () => {
        const token = captchaRef.current.getValue();
        //console.log("RECAPTCHA TOKEN", token);
        dispatch(storeReCaptchaToken(token));
        dispatch(storeHasToken(true));
        //COMMENT: Do we need a reset? It is reset autmatically on refresh
        //captchaRef.current.reset();
    }

    return(
        <div className='summary-main-container'>
            <div className='summary-contact-info'>
                <SummaryContactDisplay name={ formsData.name }
                                       email={ formsData.email }
                                       phone={ formsData.phone } />
            </div>
            <div className="summary-grid-container">
                {collectedData.map((data) =>
                    <div className="summary-dataDisplay" key={ data.id }>
                        <SummaryDataDisplay header={ data.header } 
                                            content={ data.content }/>
                    </div>
                )}
            </div>
            <div className="summary-recaptcha-container">
                <ReCAPTCHA
                           sitekey={process.env.REACT_APP_SITE_KEY_RECAPTCHA }
                           theme="light"
                           ref={captchaRef}
                           onChange={handleRecaptchaCheck} />
            </div>        
        </div>
    );
}

export default Summary;