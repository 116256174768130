import '../Backstep/Backstep.css';
import { BackStep } from '../../../icons';
import { Misc } from '../../../constants';
import { IconSize } from '../../../Services/MediaQueryServices';

const Backstep = () => {

    const size = IconSize();
    
    return(
        <div className='backstep-container'>
            <BackStep className="arrow-back" color="var(--main-color)" size={ size }  />
            <p className='backstep'>{ Misc.backStep }</p>
        </div>
    );
}

export default Backstep;