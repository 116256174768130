
import '../SearchBo/SearchBo.css';

import { MagnifyingGlass, ClearOrClose } from '../../../icons';
import { storeBoIssueId } from '../../../Features/BackofficeData/BackofficeDataSlice';
import { GetIssueIds } from '../../../Services/BackofficeServices';
import * as Constants from '../../../constants'; 

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Code and inspiration from: https://www.youtube.com/watch?v=x7niho285qs&t=415s
const SearchBo = ({ name, id, form, placeholder, data }) => {
    const dispatch = useDispatch();
    const [filteredData, setFilteredData] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [idExists, setIdExists] = useState(true);
    const [beginSearch, setBeginSearch] = useState(true);
    const { issueId } = useSelector((state) => state.backofficeData);  
    const issueIds = GetIssueIds( data );
    
    
    const handleFilter = ( event ) => {
        setBeginSearch(false);
        const searchInput = event.target.value;
        setInputValue(searchInput);
        const filter = issueIds.filter(( value ) => {
            return value.toString().startsWith( searchInput );
        })
        if(filter.length === 0){
            setInputValue (Constants.ErrorMessages.noSuchIssue + searchInput);
            setIdExists(false);
            
        }
        console.log("filter", filter);
        searchInput === "" ? setFilteredData([]) : setFilteredData(filter);
    };
    
    const ClearInput = () => {
        console.log( "data cleared" )
        dispatch(storeBoIssueId(0));
        setInputValue("");
        setFilteredData([]);
        setBeginSearch(true);
        setIdExists(true);
    }

    const SetChosenId = ( id ) => {
        setInputValue( id );
        dispatch(storeBoIssueId(id));
        setFilteredData([]);
    }
    return(
        <div className="bo-search-bar">
            <div className="bo-search">
                <label htmlFor={ name }>Sök</label>
                <div className="input-icon">
                { issueId === 0 ? 
                <input name={ name } id={ id } type="text" className={ idExists ? "search-issue" : "danger" } 
                    value={ inputValue } placeholder={ placeholder } pattern='[A-z]' onChange={ handleFilter }
                    disabled={ !idExists } /> :
                <input name={ name } id={ id } type="text" className={ idExists ? "search-issue" : "danger" } 
                    value={ inputValue }  pattern='[A-z]' onChange={ handleFilter } required /> 
                }
                  
                <span className="bo-input-icon-container" onClick={ ClearInput }>
                    { beginSearch  ? 
                    <MagnifyingGlass name="Search" color="var(--dark-color)" size="0.9rem" /> 
                    : <ClearOrClose name="clear-btn" colour="var(--dark-color)" size="0.9rem" />}
                </span>
                </div>
            </div>
            {filteredData.length !== 0 && (
            <div className="bo-data-result">
                { filteredData.map( ( id ) => {
                    return <p className='bo-data-item'
                              onClick={ () => SetChosenId( id ) }>{ id }</p> 
                })}
            </div>
            )}
        </div>
    );
}

export default SearchBo;