import * as Constants from '../../constants';

const SummaryContactDisplay = ( { name, email, phone } ) => {

   if(name === "" || name === undefined) { name = Constants.Summary.noInfo; } 
   if(email === "" || email === undefined) { email = Constants.Summary.noInfo; } 
   if(phone === "" || phone === undefined) { phone = Constants.Summary.noInfo; }

    return(
        <>
            <p style={{ color:"var(--main-color)", fontWeight:"var(--text-body-header-weight)"}}>{ Constants.Summary.contact } </p>
            <p style={{ color:"var(--light-color)", fontWeight:"var(--text-body-header-weight)" }}>{ Constants.Summary.name + " " } <span>{ name }</span></p> 
            <p style={{ color:"var(--light-color)", fontWeight:"var(--text-body-header-weight)" }}>{ Constants.Summary.email + " " } <span>{ email }</span></p> 
            <p style={{ color:"var(--light-color)", fontWeight:"var(--text-body-header-weight)" }}>{ Constants.Summary.phone + " "  } <span>{ phone }</span></p> 
        </>
    );
}

export default SummaryContactDisplay