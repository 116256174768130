// import axios from "axios";
// import * as Constants from '../constants';

import * as XLSX from 'xlsx';

import { DateTime } from "luxon";

export const GetMunicipalityOptions = ( issues ) => {
    const MunicipalitiesInIssues = issues.map( issue => {
        const option = { value: issue.municipality.id, label: issue.municipality.longName };
        return option;
      });
    
      const DistinctMunicipalitiesOptions = [];
      const map = new Map();
      for (const option of MunicipalitiesInIssues) {
          if(!map.has(option.value)){
              map.set(option.value, true);    // set any value to Map
              DistinctMunicipalitiesOptions.push({
                  value: option.value.toString(),
                  label: option.label
              });
          }
      }
      //above solution taken from: https://codeburst.io/javascript-array-distinct-5edc93501dc4
      return DistinctMunicipalitiesOptions;
}

export const FilterIssues = ( issues, backofficeData ) => {
    const filteredList = [];
    const issuesWithinDateSpan = [];

    issues.forEach(issue => {
        if( DateTime.fromSQL(issue.dateCreated).toFormat('yyyy MM dd').replace(/ /g,"-") >= DateTime.fromISO(backofficeData.date.min).toFormat('yyyy MM dd').replace(/ /g,"-")
        && DateTime.fromSQL(issue.dateCreated).toFormat('yyyy MM dd').replace(/ /g,"-") <= DateTime.fromISO(backofficeData.date.max).toFormat('yyyy MM dd').replace(/ /g,"-")){
          
          issuesWithinDateSpan.push( issue );
        }
    });
    //console.log("issuesWithinDateSpan", issuesWithinDateSpan)
  

    if(backofficeData.municipalityId !== 0 &&  backofficeData.issueId === 0){
        issuesWithinDateSpan.forEach(issue => {
           if(issue.municipality.id === backofficeData.municipalityId)  filteredList.push(issue); 
        });
    }
    else if(backofficeData.issueId !== 0){
        filteredList.push(issues.find( issue => issue.id === backofficeData.issueId))
    }

    if(filteredList.length === 0) { return issuesWithinDateSpan.filter((item) => {
        if(backofficeData.obstacleId !== 0) {
            if(backofficeData.obstacleId === item.obstacle.id) {
                return true;
            }
            return false;
        }
        return true;
    }) }
    else { return filteredList.filter((item) => {
        if(backofficeData.obstacleId !== 0) {
            if(backofficeData.obstacleId === item.obstacle.id) {
                return true;
            }
            return false;
        }
        return true;
    }) 
    } 
}

export const GetIssueIds = ( issues ) => {
    const ids = [];

    issues.forEach(issue => {
        ids.push( issue.id )
    });

    return ids;
}

export const ExportIssues = ( issues ) => {

        var excelData = [];

        issues.forEach(issue => {
            var sheetRow = {
                Ärendenr: issue.id,
                SkickatTill: issue.municipality.longName,
                Hinder: issue.obstacle.name,
                Beskrivning: issue.description,
                Plats: issue.location,
                Datum: issue.dateCreated,    
            }
            excelData.push(sheetRow);
        });
        //console.log("sheetRow", excelData);
        var excelWorkbook = XLSX.utils.book_new(),
        excelWorkSheet = XLSX.utils.json_to_sheet( excelData );

        XLSX.utils.book_append_sheet(excelWorkbook, excelWorkSheet,"Exporterade ärenden"  )

        XLSX.writeFile(excelWorkbook, `DHR_Export_${DateTime.now().toFormat('yyyy MM dd').toString()}.xlsx`);

        //Start of serverside export
        // console.log("List of issue ids", issueIds);
        // const url = Constants.URLs.postExport;  
        // axios.post(url, issueIds);
}

export const IsAuthorizedUser = ( token ) => {
   if ( (token === "" || token === undefined) && sessionStorage.getItem("Token") === null  ){ return false; } 
   else { return true; }
}
