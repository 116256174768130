import '../Photo/Photo.css';

import UploadPhoto from '../UploadPhoto/UploadPhoto';

import { ClearOrClose } from '../../../icons';
import { IconSize, ImgSize } from '../../../Services/MediaQueryServices';
import { storePhoto } from '../../../Features/FormsData/FormsDataSlice';
import { storePhotoName } from '../../../Features/FormsData/FormsDataSlice';

import { useDispatch } from 'react-redux';
//import { useState } from 'react';

const Photo = ({ payload, title }) => {

    const dispatch = useDispatch();
    //COMMENT lines that are commented out can be used to set an "ok" border on photo container
    //const photoContainerDiv = document.getElementById('photo-border'); 
    const size = IconSize();
    let imgSize = ImgSize(size);


    //It is not possible to store non-serializable values (like date) so the file needs to be
    //changed into a dataURL (string)
    const handleLoadedFile = ( event ) => {
        dispatch(storePhotoName(event.target.files[0].name));
        const reader = new FileReader();
        reader.readAsDataURL( event.target.files[0] );
        reader.onload = () => { dispatch(storePhoto(reader.result)); }
    }

    const NoPhoto = () => {
        return(
        <div className='no-photo-container'>
            <UploadPhoto whenClicked={ handleLoadedFile } />
        </div> 
        );
    };

    const ShowPhoto = () => {
        //photoContainerDiv.style.border='var(--ok-color) 3px solid';
        return(
            <>
            <img src={ payload } id="displayed-img" alt="Not Found"  height={ imgSize } />
            <div className="clear-photo-icon-container" onClick={ removePhoto }>
                <ClearOrClose color="var(--main-color)" size={ size } />
            </div>
            </> 
        );
    }

    const removePhoto = () => {
        // photoContainerDiv.style.border='var(--main-color) 3px solid';
        dispatch(storePhoto(""));
    }
    return(
        <div className={payload === "" ? 'uploadphoto-container' : 'uploadphoto-container with-photo' } title={ title }>
            { payload === "" ? < NoPhoto /> : <ShowPhoto /> }
        </div>
    );
}

export default Photo;