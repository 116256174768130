import * as Constants from '../constants';
import * as Icons from '../icons';
// import { SendData } from '../Features/FormsData/FormsDataSlice';
// import { ObstacleFormApproved } from './FormsServices';

export const TextBtnLG = () => {
    switch ( window.location.pathname ) {
        case '/':
          return Constants.Start.button;
        case '/obstacle':
          return Constants.Obstacle.button;
        case '/contact':
          return Constants.Contact.button;
        case '/summary':
          return Constants.Summary.button;
        case '/thankyou':
          return Constants.End.button;
          case '/notfound':
          return Constants.NotFound.button;
        case '/login':
          return Constants.Login.linkText;
        default:
          break;
    }
}

export const IconBtnLG = () => {


  
    switch ( window.location.pathname ) {
        case '/':
        case '/obstacle':
        case '/contact':    
          return (<Icons.ForwardStep color="var(--dark-color)" strokeWidth="5" />);
        case '/summary':
          return (<Icons.Send color="var(--dark-color)" size="1.5rem" />);
        case '/thankyou':
          return (<Icons.ClearOrClose color="var(--dark-color)" size="1.5rem" />);
        case '/notfound':
          return;
        case '/login':
          return (<Icons.BackStep color="var(--dark-color)" size="1.5rem" /> )
        default:
          break;
    }
}

export const GetHeader = () => {
  switch ( window.location.pathname ) {
      case '/obstacle':
        return Constants.Obstacle.header;
      case '/contact':
        return Constants.Contact.header;
      case '/summary':
        return Constants.Summary.header;
        case '/notfound':
        return Constants.NotFound.button;
      default:
        break;
  }
}

export const DisplayHeaderLogoBackstep = () => {
  switch ( window.location.pathname ) {
      case '/*':
        return false;
      case '/obstacle':
        return true;
      case '/contact':
        return true;
      case '/summary':
        return true;
      default:
        break;
  }
}

export const DisplayMainBtn = () => {
  switch ( window.location.pathname ) {
    case '/*':
      return false;
    case '/':
      return true;
    case '/obstacle':
      return true;
    case '/contact':
      return true;
    case '/summary':
      return true;
    case '/thankyou':
      return false;
    case '/login':
      return false;
    case '/somethingwentwrong':
      return false;
    case '/somethingwentwrongbackoffice':
      return false;
    default:
      break;
  }
}

export const DisableMainBtn = (hasToken) => {
  switch ( window.location.pathname ) {
    case '/summary':
      if (hasToken) {
        return false;
      }
      else{
        return true
      }  
      default:
        break    
  }
  return false;
}