import '../404/NotFound404.css';

import * as Constants from '../../constants';
import DHRLogo from '../../Components/Btns, icons & logos/DHRLogos/DHRLogo';
import { IconSize } from '../../Services/MediaQueryServices';
import { BackStep } from '../../icons';

import { Link } from 'react-router-dom';

const NotFound = () => {

    const size = IconSize();
    return(
        <div className='not-found-main-container'>
            <div className='not-found-logo-container'>
                <DHRLogo />
            </div>
            <div className="not-found-text-container">
                <h1 className='not-found-header'>{Constants.NotFound.header}</h1>
                <p className="not-fount-information">{Constants.NotFound.info}</p>
                <p className="not-fount-information">{Constants.NotFound.info2}</p>
                <p className="not-fount-information">{Constants.NotFound.instruction}</p>
            </div>
            <Link to={ '/' }>

                <div className="not-found-btn-container">
                    <div className="not-found-btn-icon-container">{ <BackStep size={ size }/>}</div>
                    <div className="not-found-btn-text">{ Constants.GDPR.button}</div>
                </div>
            </Link>
        </div>
    );
}

export default NotFound;