export const Start = {
   header: "Om appen",
   description: "Här anmäler du bristande användbarhet i publika lokaler och offentlig miljö, exempelvis bibliotek, övergångsställe eller café.",
   instruction: "Välj typ av hinder i listan och lägg till plats, foto, beskrivning och kontaktuppgifter.",
   information: "Din anmälan kommer sedan att skickas till aktuell kommun för vidare behandling.",
   button: "Anmäl hinder",
   imgAlt: "Kvinna i rullstol",
}

export const Obstacle = {
   header: "Information om hindret",
   type: "* Välj typ av hinder",
   toolTipType: "Klicka och välj sedan ett hinder ur listan.",
   place: "* Beskriv plats för hindret, t.ex. adress",
   toolTipPlace: "Till exempel \"Storgatan 15\" eller \"Hötorget\" Max 25 tecken. Inga specialtecken.",
   errorPlace: "Något är fel i texten",
   search: "* Sök och ange kommun",
   toolTipSearch: "Börja skriva in namn på kommun och välj sedan från listan.",
   desc: "* Beskriv problemet",
   toolTipDesc: "Förklara så tydligt som möjligt med egna ord. Max 200 tecken.  Inga specialtecken.",
   photo: "Lägg till ett foto",
   toolTipPhoto: "Välj ett sparat foto eller ta ett nytt.",
   button: "Nästa",
   required: "Fält med * är obligatoriska ",
   default: "Default",
}

export const Contact = {
   header: "Dina kontaktuppgifter",
   name: "* Förnamn och Efternamn",
   toolTipName: "Till exempel \"Bengt Fransson\". Max 25 tecken. Inga specialtecken eller siffor.",
   email: "* Epostadress",
   toolTipEmail: "Fyll i en giltig epostadress",
   phone: "* Telefonnummer",
   toolTipPhone: "Endast siffror.",
   followUp: "Jag vill få återkoppling på min anmälan",
   approve: "* Godkänn DHR's hantering av din data.",
   GDPR: "Läs mer om vår hantering av data och GDPR",
   button: "Nästa",
   yes: "Ja",
   no: "Nej",
}

export const Summary = {
   header: "Granska din anmälan",
   place: "Plats:",
   type: "Typ av hinder:",
   desc: "Beskrivning:",
   contact: "Dina uppgifter",
   name: "Namn:",
   email: "Email:",
   phone: "Telefon:",
   photo: "Foto:",
   sendTo: "Anmälan skickas till:",
   wantFeedback: "Återkoppling begärd:",
   approvedGDPR: "GDPR godkänd: ",
   button: "Skicka anmälan",
   noInfo: "Information saknas",
}

export const End = {
   header: "Tack för din anmälan!",
   confirmation: `Ditt ärende har nu skickats till den kommun du valt
                  och ett bekräftelsemail har gått iväg till den email-adress du angett.`,
   information: "Notera att om du har fyllt i att du önskar återkoppling så är det kommunen som kommer att sköta denna.",
   instruction: "Du kan nu stänga ner appen eller klicka på någon av länkarna nedan för att gå vidare.",
   linkText: "DHR.se",
   button: "Stäng appen",
   newIssue: "Till starten",
   linkHref: "https://dhr.se/",
}

export const Login = {
   header: "Fyll i dina uppgifter.",
   userName: "Användarnamn",
   toolTipUserName: "Fyll i det användarnamn du valt.",
   password: "Lösenord",
   tooTipPassword: "Fyll i det lösenord du valt.",
   forgot: "Glömt lösenord? Klicka här.",
   linkText: "Tillbaka till appen",
   button: "Logga in",
}
export const Register = {
   header: "Registrera nytt konto",
   userName: "Användarnamn",
   toolTipUserName: "Fyll i det användarnamn du valt.",
   password: "Lösenord",
   tooTipPassword: "Fyll i det lösenord du valt.",
   forgot: "Glömt lösenord? Klicka här.",
   linkText: "Tillbaka till appen",
   button: "Logga in",
}
export const NotFound = {
   header: "Något gick fel.",
   info: "Sidan du försöker nå finns inte.",
   info2: "Den kan vara flyttad, borttagen eller aldrig skapats.",
   instruction: "Använd länken nedan för att komma tillbaka till appen.",
   button: "Tillbaka",
}

export const GDPR = {
   header: "Vi värnar om din data",
   paragraph1: "Informationen som du lämnar samlas in av DHR som delar informationen med respektive ansvarig kommun. Syftet är att anmäla enkelt avhjälpta hinder samt att användas för påverkansarbete. För denna behandling behöver DHR ditt samtycke. Du kan när som helst ta tillbaka ditt samtycke. Om du vill ta tillbaka ditt samtycke, återkalla samtycket här: ",
   paragraph2: "Ett återkallande påverkar inte lagligheten av behandling grundad på samtycke innan det återkallas. Här kan du läsa mer om hur DHR behandlar personuppgifter: ",
   linkText: "dhr.se/integritetspolicy",
   button: "Tillbaka ",
}

export const BackOffice = {
   header: "Inskickade ärenden",
   link: "Till DHR.se",
   logout: "Logga ut",
   imgAltText: "Bild på en man",
   obstacleImgAltText: "Bild på hindret",
   search: "Sök ärendenummer...",
   locationFilter: "Alla orter",
   issuesPerPage: "Ärenden per sida",
   from: "Ärenden från datum",
   to: "Ärenden till datum",
   export: "Exportera visad lista",
   name: "Namn",
   email: "E-post",
   phone: "Telefonnr.",
   sentTo: "Skickad till",
   location: "Plats",
   obstacle: "Hinder",
   issueId: "Ärendenummer",
   date: "Inskickat",
   extra: "Mer information om ärende: ",
   description: "Beskrivning",
   image: "Bild",
   moreInfo: "Mer info",
}

export const URLs = {
   DHRHome: "https://www.dhr.se",
   DHRIntegrity: "https://dhr.se/integritetspolicy/",

   base: "https://dhr-webapp-swc.azurewebsites.net/",

   getMunicipalities: "dbcontext/getmunicipalities",
   getObstacles: "dbcontext/getobstacles",
   getIssues: "/dbcontext/getissues",

   postIssue: "/dbcontext/saveissue",
   postLogin: "/dbcontext/login",
   postRegister: "/dbcontext/register",
   postExport: "dbcontext/excelexport",
   postToken: "/dbcontext/refreshtoken",

   postReCapthaToken: "/dbcontext/verifyReCaptchaToken",

   testGetMe: "/dbcontext/getme",
}

export const Misc = {
   appContactMail: "bortmedhindret@dhr.se",
   logoAltText: "DHR's logotyp",
   backStep: "Tillbaka",
   star: "*",
}

export const ErrorMessages = {
   mainHeader: "Något gick fel",
   information: "Vänligen stäng ner appen och försök igen lite senare.",
   instruction: "Om felet kvarstår anmäl felet till: ",
   supportLink: "",
   loginDataMissing: "Användarnamn och/eller lösenord saknas",
   loginDataIncorrect: "Användarnamnet och/eller lösenordet är fel",
   noSuchIssue: "Inget ärende börjar på ",
   errorInformationHeader: "Följande fel har inträffat.",
   errorInformationInstructions: "Ange dessa vid felanmälan",
   errorMessage: "Felmeddelande",
   errorType: "Felet kommer ifrån",


}

