import '../1_Start/Start.css';

import DHRLogo from '../../Components/Btns, icons & logos/DHRLogos/DHRLogo';
import landingPageImg from '../../Assets/landingPageImg.svg';
import * as Constants from '../../constants';

const Start = () => {
  
  return (
    <div className="start-main-container">
        <div className='start-logo-container'>
          <DHRLogo className="start-logo" />
        </div>
      <div className='start-img-container'>
        <img src={ landingPageImg } alt={ Constants.Start.imgAlt }className="start-img" />
      </div>
      <div>
        <div className='start-text-container'>
          <h1 className='start-header'>{ Constants.Start.header }</h1>       
          <p className='start-body'>{ Constants.Start.description }</p>
          <p className='start-body'>{ Constants.Start.information }</p>
        </div>
      </div>
    </div>
  );
}

export default Start;