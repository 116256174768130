import '../2_ObstacleInformation/ObstacleInformation.css';

import * as Constants from '../../constants';
import ObstacleForm from '../../Components/Forms & Inputs/ObstacleForm/ObstacleForm';

import { useSelector } from 'react-redux';

import { ObstacleFormApproved } from '../../Services/FormsServices';

const ObstacleInformation = () =>{
    const formsData = useSelector((state) => state.formsData);

    return(
        <div className='obstacle-main-container'>
            <div className='obstacle-form-container'>
                <ObstacleForm/>
            </div>
            <p className={ ObstacleFormApproved(formsData) ? 'form-message display-none' : 'form-message' }>{ Constants.Obstacle.required }</p>
        </div>
    );
}

export default ObstacleInformation;