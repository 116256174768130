import '../Login/LoginBo.css';

import InputTextBo from '../../Components/Backoffice/InputTextBo/InputTextBo';
import BtnBoLg from '../../Components/Backoffice/BtnBoLg/BtnBoLg';
import backOfficeLogo from '../../Assets/backOfficeLogo.svg';
import { storeToken } from '../../Features/LoginData/LoginDataSlice';
import { Padlock } from "../../icons";
import * as Constants from "../../constants";

import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import axios from 'axios';

export const LoginBo = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userName, password } = useSelector( (state) => state.loginData );
    const [ishidden, setIsHidden] = useState(true);
    const [iswrong, setIsWrong] = useState(false);
    
    const HandleKeyDown = (event) => {
        if(event.key === 'Enter'){ 
            event.preventDefault();
            HandleLogin(); 
        }
    }
    //document.addEventListener('keydown', HandleKeyDown);

    const HandleLogin = () => {
        if( (userName === undefined || userName === "") || (password === undefined || password === "")){
            setIsHidden(false);
            setIsWrong(false);
        }
        else{
            const devUrl = Constants.URLs.postLogin;
            const prodUrl = Constants.URLs.base + devUrl;
            let url = "";
            process.env.NODE_ENV === 'development' ? url = devUrl : url = prodUrl;

            const payload = { Email: userName, 
                              Password: password };
            
            axios.post(url, payload )
                 .then( (response) => {
                    dispatch(storeToken(response.data.token))
                    sessionStorage.setItem("Token", response.data.token);
                    setIsHidden(true);
                    setIsWrong(false);
                    navigate("/backoffice");
                    })
                 .catch(function (error) {
                    console.log("error",error.response.data);
                    setIsWrong(true);
                    setIsHidden(false);
                    });
            }
    }

    return(
        <div className='login-bo-main-container'>
            <div className='login-bo-logo-container'>
                <img src={ backOfficeLogo } alt={ Constants.BackOffice.imgAltText } className="login-bo-logo" />
            </div>
            <h1 className='login-bo-header'>{Constants.Login.header }</h1>
            
            <form id="login-bo-form" className='login-form'>
                <InputTextBo name="login-username"
                            id="login-username"  
                            type="text" 
                            placeholder={ Constants.Login.userName }
                            payload={ userName }
                            store={ "storeUsername" } 
                            form="login-form"
                            validation="^[A-ZÅÄÖ0-9_\- ]{0,25}$"
                            title={ Constants.Login.toolTipUserName }
                            onKeyDown={ HandleKeyDown } />
    
                <InputTextBo name="login-password"
                            id="login-password"  
                            type="password" 
                            placeholder={ Constants.Login.password }
                            payload={ password }
                            store={ "storePassword" } 
                            form="login-form"
                            validation="^[A-ZÅÄÖ0-9_\- ]{0,12}$"
                            title={ Constants.Login.tooTipPassword }
                            onKeyDown={ HandleKeyDown } />

                { iswrong ? 
                <p className={ ishidden ? "display-none" : "error-message" }>{ Constants.ErrorMessages.loginDataIncorrect }</p> :
                <p className={ ishidden ? "display-none" : "error-message" }>{ Constants.ErrorMessages.loginDataMissing }</p>
                }

            <div className='login-bo-btn-container'>
                <BtnBoLg   text={ Constants.Login.button }
                            icon={ <Padlock color="var(--bo-main-color)" size='1.5rem' stroke="none" /> }
                            whenClicked={ HandleLogin } />
            </div>

                {/* <p className='forgot-link'>{ Constants.Login.forgot }</p> */}
            </form>
        </div>
    );
}
export const Register = () => {

    const navigate = useNavigate();
    const { userName, password } = useSelector( (state) => state.loginData );
    const [ishidden, setIsHidden] = useState(true);
    const [iswrong, setIsWrong] = useState(false);

    const HandleKeyDown = (event) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            console.log("login input: ",userName, password);
            HandleLogin();
        }
    }
    //document.addEventListener('keydown', HandleKeyDown);

    const HandleLogin = () => {
        if( (userName === undefined || userName === "") || (password === undefined || password === "")){
            setIsHidden(false);
            setIsWrong(false);
        }
        else{
            const devUrl = Constants.URLs.postRegister;
            const prodUrl = Constants.URLs.base + devUrl;
            let url = "";
            process.env.NODE_ENV === 'development' ? url = devUrl : url = prodUrl;

            const payload = { Email: userName,
                Password: password };

            axios.post(url, payload )
                .then( (response) => {
                    navigate("/backoffice");
                })
                .catch(function (error) {
                    console.log("error",error.response.data);
                    setIsWrong(true);
                    setIsHidden(false);
                });
        }
    }

    return(
        <div className='login-bo-main-container'>
            <h1 className='login-bo-header'>{Constants.Register.header }</h1>

            <form id="login-bo-form" className='login-form'>
                <InputTextBo name="login-username"
                             id="login-username"
                             type="text"
                             placeholder={ Constants.Login.userName }
                             payload={ userName }
                             store={ "storeUsername" }
                             form="login-form"
                             validation="^[A-ZÅÄÖ0-9_\- ]{0,25}$"
                             title={ Constants.Login.toolTipUserName }
                             onKeyDown={ HandleKeyDown } />

                <InputTextBo name="login-password"
                             id="login-password"
                             type="password"
                             placeholder={ Constants.Login.password }
                             payload={ password }
                             store={ "storePassword" }
                             form="login-form"
                             validation="^[A-ZÅÄÖ0-9_\- ]{0,12}$"
                             title={ Constants.Login.tooTipPassword }
                             onKeyDown={ HandleKeyDown } />

                { iswrong ?
                    <p className={ ishidden ? "display-none" : "error-message" }>Något gick fel. Användaren kanske redan finns.</p> :
                    <p className={ ishidden ? "display-none" : "error-message" }>{ Constants.ErrorMessages.loginDataMissing }</p>
                }

                <div className='login-bo-btn-container'>
                    <BtnBoLg   text={ "Skapa konto" }
                               icon={ <Padlock color="var(--bo-main-color)" size='1.5rem' stroke="none" /> }
                               whenClicked={ HandleLogin } />
                </div>

                {/* <p className='forgot-link'>{ Constants.Login.forgot }</p> */}
            </form>
        </div>
    );
}
