import '../UploadPhoto/UploadPhoto.css';

import { Obstacle } from '../../../constants';
import { Photo } from '../../../icons';
import { IconSize } from '../../../Services/MediaQueryServices';

const UploadPhoto = ( { whenClicked } ) => {

    let size = IconSize();

    switch (size) {
        case '1rem':
            size = '1.5rem';
            break;
        case '1.2rem':
            size = '2rem';
            break;
        case '1.5rem':
            size = '2.2rem'; 
            break;   
        default:
            break;
    }

    return(
        <div className="photo-input-container">
            <label htmlFor="upload-photo" className="custom-upload-photo">{ Obstacle.photo }</label>
            <input type="file" name="upload-photo" id="upload-photo" onChange={ whenClicked } style={ {position: "absolute", left: "-99999rem"} } />
            <Photo color="var(--main-color)" size={ size } name="photo-icon"/>
        </div>
    );
}

export default UploadPhoto;

//idea for custom file-input: https://stackoverflow.com/questions/572768/styling-an-input-type-file-button