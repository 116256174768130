export const ObstacleFormApproved =  ( formsData ) => {

    const allDataValues = Object.values( formsData ).slice(0, 7);
    // console.log("allDataValaue", allDataValues)
    const approved = allDataValues.includes("");
    // console.log("trueORFalse", containsBlanks)
    return !approved;
}

export const ContactFormApproved = ( formsData ) => {
    const allDataValues = Object.values( formsData ).slice(9, 14);
    // console.log("allDataValaue", allDataValues)
    const containsBlanks = (allDataValues.includes(""));
    const GDPRNotApproved = formsData.approveGDPR === false;
    if(containsBlanks || GDPRNotApproved || (containsBlanks && GDPRNotApproved)){
        return false;
    }
    else{
        return true;
    }
}