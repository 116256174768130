import * as Constants from '../../constants';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
    reCaptchaToken: "",
    hasToken: false,
};

export const VerifyReCaptcha = createAsyncThunk('reCaptcha/postToken', async ( token ) => {

    //const secretKey = process.env.REACT_APP_SECRET_KEY_RECAPTCHA;
    const payload = {token: token }
    const devUrl = Constants.URLs.postReCapthaToken;
    const prodUrl = Constants.URLs.base + devUrl;
    let url = "";
    process.env.NODE_ENV === 'development' ? url = devUrl : url = prodUrl;

    return await axios.post(url, payload)
         .then(response =>{
           console.log("Svar från Backend", response)
           return response.data
       })
         .catch((error) => {
           console.log("Error in SendData, not action",error);
           throw error 
       })
        
 })

const reCaptchaSlice = createSlice({
    name: "reCaptcha",
    initialState,
    reducers: {
        storeReCaptchaToken: ( state, token ) => {
            state.reCaptchaToken = token.payload;
        },
        storeHasToken: ( state, hasToken ) => {
            state.hasToken = hasToken.payload;
        },
    },
    extraReducers: {
        [VerifyReCaptcha.pending]: ( state ) => {
            state.isLoading = true;
        },
        [VerifyReCaptcha.fulfilled]: ( state, action ) => {
            console.log("recaptchaction",action);
            state.isLoading = false;
            // state.municipalities = action.payload;
        },
        [VerifyReCaptcha.rejected]: ( state, action ) => {
            state.isLoading = false;
            console.log("ERROR ACTION SEND", action);
            //alert(`Ett fel inträffade när datan skickades: ${action.error.message}`)
            state.errorMessage = action.error.message;
            state.errorType = action.type;
        }
    }
});

export const { 
               storeReCaptchaToken,
               storeHasToken,
             } 
               = reCaptchaSlice.actions;

export default reCaptchaSlice.reducer;