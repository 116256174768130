import '../BtnLg/BtnLg.css'
import { Link } from 'react-router-dom';

const BtnLg = ( { text, icon, whenClicked, nextPage, disableMainBtn } ) => {    
    return(
        <Link to={!disableMainBtn ? nextPage : '#'}>
        <button className='main-btn' onClick={whenClicked} disabled={disableMainBtn}>{text} 
        <span className='main-btn-icon-container'>{icon}</span></button>
        </Link>
    );
}

export default BtnLg;
