import '../Backoffice/Backoffice.css';

import * as Constants from '../../constants';
import BtnBo from '../../Components/Backoffice/BtnBo/BtnBo';
import backOfficeLogo from '../../Assets/backOfficeLogo.svg';
import backOfficeImg from '../../Assets/backOfficeImg.svg';
import SelectBo from '../../Components/Backoffice/SelectBo/SelectBo';
import SearchBo from '../../Components/Backoffice/SearchBo/SearchBo';
import DateBo from '../../Components/Backoffice/DateBo/DateBo';
import MUIdataTable from '../../Components/Backoffice/MUIdataTable/MUIdataTable';
import { GetMunicipalityOptions, FilterIssues, ExportIssues, IsAuthorizedUser } from '../../Services/BackofficeServices';
import { Globe,LogOut, Export } from '../../icons';
import { GetIssues } from '../../Features/Issue/IssueSlice';
import { GetMinMaxDateFromBo } from '../../Features/BackofficeData/BackofficeDataSlice';
import { storeToken, storeUsername, storePassword } from '../../Features/LoginData/LoginDataSlice';

import { useDispatch, useSelector } from 'react-redux';
import {useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// import Data from '../../Assets/MOCK_DATA_ISSUES.json';

const Backoffice = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const backofficeData = useSelector((state) => state.backofficeData);
  const { token } = useSelector((state) => state.loginData);
  const { obstacleTypes } = useSelector((state) => state.obstacleType);
  // COMMENT: Below lines for next step in authorization
  // const token = sessionStorage.getItem("Token");
  // const config = { headers: { Authorization: `Bearer ${token}` }};
  // #end region

  useEffect(() => {
    dispatch(GetIssues());
    if (sessionStorage.getItem("Token") !== null){ dispatch(storeToken(sessionStorage.getItem("Token"))); }
    if(!IsAuthorizedUser( token )){ navigate("/login") };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  const { issues } = useSelector((state) => state.issue);

  useEffect(() => {
    dispatch(GetMinMaxDateFromBo( issues ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issues]);

  const filteredIssues = FilterIssues( issues, backofficeData );
  const MunicipalityOptions = GetMunicipalityOptions( issues ); 

  const ExportList = () => {
    ExportIssues( filteredIssues );
  }

  const HandleLogOut = () => {
    console.log("LOGOUT")
    sessionStorage.clear();
    dispatch(storeToken(""));
    dispatch(storeUsername(""));
    dispatch(storePassword(""));
  }


  //MOCKDATA
  // const mockOptions = [
  //   {value: 1, label: "Borås"},
  //   {value: 2, label: "Gävle"},
  //   {value: 3, label: "Mark"},
  //   {value: 4, label: "Skene"},
  //   {value: 5, label: "Ulricehamn"},
  //   {value: 6, label: "Göteborg"},
  //   {value: 7, label: "Haninge"},
  // ]
  // const issueId = [
  //   "apa", "häst", "björn", "isbjörn", "katt", "räv", "uggla", "citronfjäril", "delfin", "elefant"
  // ];
  //#end region

  return (
    <div className="main-backoffice-container">

      <div className='top-row-container'>
        <div className='bo-logo-container'>
          <img src={ backOfficeLogo } alt={ Constants.Misc.logoAltText } />
        </div>

        <div className='temp-back-to-app'>
          <Link to="/"><p className='temp-text'>Tillbaka till appen</p></Link>
        </div>

        <div className="link-logout-container">
          <a href={ Constants.URLs.DHRHome }  rel="noreferrer" target="_blank">
            <div className='bo-link-container'>
              <BtnBo text={ Constants.BackOffice.link }
                     icon={ <Globe size="0.9rem" color="var(--bo-main-color)" /> }  />
            </div>
          </a>

          <div className='bo-log-out-container'>
            <BtnBo text={ Constants.BackOffice.logout }
                   icon={ <LogOut size="0.9rem" color="var(--bo-main-color)" /> }
                   whenClicked={ HandleLogOut } />
          </div>
        </div>
      </div>

      <div className="img-row-container">
        <div className='bo-img-container'>
        <p className='bo-img-text'>Tillgänglighetsärenden inskickade till kommunerna</p>
          <img className='bo-img' src={ backOfficeImg } alt={ Constants.BackOffice.imgAltText } />
        </div>
      </div>
        
        <div className="input-row-container">

          <div className="bo-filter-municipalities-container">
            <SelectBo       name="bo-municipality-select"
                            id="municipality-select"  
                            placeholder={ "Välj ort" } 
                            data={ MunicipalityOptions } 
                            label={ "Ort"}/>
          </div>
          <div className="bo-filter-municipalities-container">
            <SelectBo       name="bo-obstacle-select"
                            id="obstacle-select"
                            placeholder={ "Välj hinder" }
                            data={ obstacleTypes.map(obj => {
                              return {
                                value: obj.id,
                                label: obj.name
                              };
                            }) } 
                            label={ "Hinder"}/>
          </div>

          <div className="bo-searchbar-container">
            <SearchBo       name="bo-search-issue"
                            id="bo-search-issue"  
                            form="search-issue"
                            placeholder={ Constants.BackOffice.search }
                            data={ issues } />
          </div>

          <div className="bo-date-container">
            <DateBo         name="bo-date-input"
                            id="bo-date-from"  
                            form="bo-date-input"
                            title={ Constants.BackOffice.from }
                            labelText={ Constants.BackOffice.from }
                            defaultDate={ backofficeData.date.min }
                            min={ backofficeData.date.min }
                            max={ backofficeData.date.max } 
                            />
          </div>

          <div className="bo-date-container">
            <DateBo         name="bo-date-input"
                            id="bo-date-to"  
                            form="bo-date-input"
                            title={ Constants.BackOffice.to }
                            labelText={ Constants.BackOffice.to}
                            defaultDate={ backofficeData.date.max }
                            min={ backofficeData.date.min }
                            max={ backofficeData.date.max } 
                            />
          </div>

          <div className='bo-export-container'>
            <BtnBo text={ Constants.BackOffice.export }
                   icon={ <Export size="0.9rem" color="var(--bo-main-color)" /> }
                   whenClicked={ ExportList } />
          </div>

      </div>

      <div className="datatable-row-container">
        <div className="data-table-container">
          <MUIdataTable issues={ filteredIssues } selectRowsPerPage={backofficeData.issuesPerPage} />
        </div>
      </div>

      


    </div>
  );
}

export default Backoffice