import '../BtnBo/BtnBo.css';

const BtnBo = ( { text, icon, whenClicked }) => {
    return(
        <div className="container-bo-btn" onClick={ whenClicked }>
                <div className="content-bo-btn">
                    <div className="icon-container">{ icon }</div>
                    <div className="text-bo-btn">{ text }</div>
                </div>
            </div>
    );
}

export default BtnBo;