import * as Constants from '../../constants';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    issues: [],
    isLoading: true,

    errorMessage: "",
    errorType:"",
    errorMetaRequestId: "",
};

export const GetIssues = createAsyncThunk('issues/getIssues',  async ( refreshtoken ) => {
    // const issuesURL = Constants.URLs.getIssues;
    const devUrl = Constants.URLs.getIssues;
    const prodUrl = Constants.URLs.base + devUrl;
    let url = "";
    process.env.NODE_ENV === 'development' ? url = devUrl : url = prodUrl;

    //COMMENT: Below need for next stage in authorization
    //const refreshTokenURL = Constants.URLs.postToken;
    //# end
    
    return await axios.get(url).then(response =>{
        console.log(response)
        return response.data;
    }).catch((error) => {
        console.log(error)
        throw error;
    })
})

const issueSlice = createSlice({
    name: 'issue',
    initialState,
    extraReducers: {
        [GetIssues.pending]: ( state ) => {
            state.isLoading = true;
        },
        [GetIssues.fulfilled]: ( state, action ) => {
            // console.log(action);
            state.isLoading = false;
            state.issues = action.payload;
        },
        [GetIssues.rejected]: ( state, action ) => {
            state.isLoading = false;
            state.errorMessage = action.error.message;
            state.errorType = action.type;
            state.errorMetaRequestId = action.meta.requestId;
        }
    }
});

export default issueSlice.reducer;
