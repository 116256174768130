import '../Select/Select.css';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDown, ArrowUp, ClearOrClose } from '../../../icons';
import { IconSize } from '../../../Services/MediaQueryServices';
import { storeObstacleId, storeObstacleName } from '../../../Features/FormsData/FormsDataSlice';
import { useState, useEffect } from 'react';

const Select = ({ name, id, placeholder, obstacleName, data, title, label }) => {

    const dispatch = useDispatch();
    const [isListActive, setIsListActive] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const size = IconSize();

    const backofficeData = useSelector((state) => state.formsData);

    const sortedData = [...data].sort((typeA, typeB) => (typeA.name > typeB.name) ? 1 : -1);
    const obstacleTypesArr = sortedData.map((type) => (
        <option key={type.id} value={type.id}>
            {type.name}
        </option>
    ));
    useEffect(() => {
        console.log(backofficeData.obstacleId);
        if (backofficeData.obstacleId > 0) {
            setIsApproved(true);
        } else {
            setIsApproved(false);
        }
        setIsListActive(false)
    }, [backofficeData.obstacleId])
    const handleSelectNew = (event) => {
        const selectedId = parseInt(event.target.value);
        const selectedName = data.find(item => item.id === selectedId)?.name || '';
        dispatch(storeObstacleId(selectedId));
        dispatch(storeObstacleName(selectedName));
        setIsApproved(true);
    };
    const ClearSelect = () => {
        dispatch(storeObstacleId(""));
        dispatch(storeObstacleName(""));
        setIsApproved(false);
    };
    return (
        <div className='select-container'>
            <label htmlFor={name}>{label}</label>
            <div className='input-icon'>
            <select name={name} id={id} title={title} className={isApproved ? 'form-input approved-input' : 'form-input'}
                value={backofficeData.obstacleId} onChange={handleSelectNew}
                onClick={() => setIsListActive(!isListActive)}
                onBlur={() => setIsListActive(false)} required>
                <option>{placeholder}</option>
                {obstacleTypesArr}
            </select>
            <span id="select-icon" className="input-icon-container" onClick={ClearSelect}>
                    {isListActive ?
                        <ArrowUp color="var(--(dark-color)" size={size} /> :
                        obstacleName === "" ?
                            <ArrowDown color="var(--(dark-color)" size={size} /> :
                            <ClearOrClose color="var(--(dark-color)" size={size} />
                    }
                </span>
                </div>
        </div>
    );
}

export default Select;