import { useState, useEffect } from "react";

export const IconSize = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    let size = "";

    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

      if(windowSize.innerWidth < 400){  size='1rem' }
      else if(windowSize.innerWidth < 700) { size='1.2rem' }
      else if(windowSize.innerWidth < 1000) { size='1.2rem' }
      else if(windowSize.innerWidth >= 1000) { size='1.5rem' }
      
      return size;
    }
    
    function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }
    
//Solution from: https://bobbyhadz.com/blog/react-get-window-width-height

export const LgIconSize = ( smallIconSize ) => {
  switch (smallIconSize) {
    case '1rem':
        return '1.5rem';
    case '1.2rem':
        return '2rem';
    case '1.5rem':
        return '2.2rem'; 
    default:
        break;
}
}

export const ImgSize = ( size ) => {
  switch (size) {
    case '1rem':
       return '80px';
    case '1.2rem':
      return '100px';
    case '1.5rem':
      return '125px'; 
    default:
        break;
}
}

export const GetWindowSize = () => {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}